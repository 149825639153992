export const SOCIAL_MEDIA_LINK_FACEBOOK =
  'https://www.facebook.com/gaultmillau.schweiz';
export const SOCIAL_MEDIA_LINK_FACEBOOK_FR =
  'https://www.facebook.com/gaultmillau.suisse';
export const SOCIAL_MEDIA_LINK_INSTAGRAM =
  'https://www.instagram.com/gaultmillau_schweiz';
export const SOCIAL_MEDIA_LINK_INSTAGRAM_FR =
  'https://www.instagram.com/gaultmillau_suisse';
export const SOCIAL_MEDIA_LINK_YOUTUBE =
  'https://www.youtube.com/channel/UCe0fhq9hRLoXCfvMDNFnC-Q';
export const SOCIAL_MEDIA_LINK_LINKEDIN =
  'https://www.linkedin.com/company/gaultmillau-schweiz/';
export const SOCIAL_MEDIA_LINK_PINTEREST =
  'https://www.pinterest.com/gaultmillauschweiz/';
export const SOCIAL_MEDIA_LINK_GOOGLE_NEWS =
  'https://news.google.com/publications/CAAqBwgKMI2TpQsw0J29Aw?hl=de&gl=CH&ceid=CH%3Ade';
