import React, { ComponentType } from 'react';
import { IntlShape } from 'react-intl';
import classNames from 'classnames';
import { getAllAuthors } from '../../../Article/shared/helpers';
import CSSPicture from '../../../../../../../common/components/CSSPicture';
import { STYLE_TEASER_1_1 } from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';

type RecipeAuthorProps = {
  recipe: Recipe;
  intl: IntlShape;
  language: string;
};

const RecipeAuthor: ComponentType<RecipeAuthorProps> = ({
  recipe,
  intl,
  language,
}) => (
  <div className={styles.AuthorWrapper}>
    {recipe.chiefCook &&
      recipe.chiefCook.edges &&
      recipe.chiefCook.edges.length > 0 &&
      recipe.chiefCook.edges[0].node &&
      recipe.chiefCook.edges[0].node.teaserImage && (
        <CSSPicture
          style_320={STYLE_TEASER_1_1}
          relativeOriginPath={
            recipe.chiefCook.edges[0].node.teaserImage?.image.file
              ?.relativeOriginPath
          }
        >
          {({ className }) => (
            <span className={classNames(styles.AuthorImage, className)} />
          )}
        </CSSPicture>
      )}
    {recipe.chiefCook &&
      recipe.chiefCook.edges &&
      recipe.chiefCook.edges.length > 0 && (
        <div className={styles.AuthorWrapperInner}>
          <p className={styles.AuthorLink}>
            {getAllAuthors(recipe, recipe.chiefCook.edges, intl, false)}
          </p>
          {recipe.chiefCook.edges[0].node &&
            recipe.chiefCook.edges[0].node.description &&
            language === 'fr' && (
              <div
                className={styles.AuthorLead}
                dangerouslySetInnerHTML={{
                  __html: recipe.chiefCook.edges[0].node.description,
                }}
              />
            )}
          {recipe.chiefCook.edges[0].node &&
            recipe.chiefCook.edges[0].node.body &&
            language !== 'fr' && (
              <div
                className={styles.AuthorLead}
                dangerouslySetInnerHTML={{
                  __html: recipe.chiefCook.edges[0].node.body,
                }}
              />
            )}
        </div>
      )}
  </div>
);

export default RecipeAuthor;
