import { ApolloClient, InMemoryCache } from '@apollo/client';
import raf from 'raf';
import connectToDevTools from './helpers/connectToDevTools';
import { log } from './helpers/utils';
import rasApolloLinks from './rasApolloLinks';

// We init the cache and hydrate in a setTimeout and a requestAnimationFrame.
// This is to somewhat simulate the behaviour of a fully async hydration in
// an inline hydration by deferring the needed calculations by at least one frame
// and therefore not block anything that might need to happen earlier.
const initCache = ({ generateApolloCache }): Promise<InMemoryCache> =>
  new Promise((resolve) => {
    if (__DEVELOPMENT__) {
      resolve(generateApolloCache());
      return;
    }

    setTimeout(() => {
      raf(() => {
        let isHydrated = false;
        let state = {};
        const hydrationDataEl =
          document && document.querySelector('#hydrationdata');

        if (__CLIENT__ && hydrationDataEl) {
          let initialState;

          /**
           * hydrate if the script is executed when then page is already loaded
           */
          if (
            (document.readyState === 'complete' ||
              document.readyState === 'interactive') &&
            !isHydrated
          ) {
            try {
              initialState = JSON.parse(hydrationDataEl.innerHTML);

              state = initialState.state;

              const cache = generateApolloCache(state);

              isHydrated = true;
              resolve(cache);
              return;
            } catch (e) {
              // eslint-disable-next-line
              console.error(
                'failed to parse hydration data: readyState = complete',
                e,
                hydrationDataEl.innerHTML,
              );

              resolve(generateApolloCache());
              return;
            }
          }

          document.addEventListener('readystatechange', () => {
            if (
              (document.readyState === 'interactive' ||
                document.readyState === 'complete') &&
              !isHydrated
            ) {
              try {
                initialState = JSON.parse(hydrationDataEl.innerHTML);
              } catch (e) {
                // eslint-disable-next-line
                console.error(
                  'parsing hydration data failed',
                  e,
                  hydrationDataEl.innerHTML,
                );

                // return empty cache
                resolve(generateApolloCache());
                return;
              }

              state = initialState.state;
              log(
                'asyncConfigureApolloClient',
                [
                  'state',
                  state,
                  'global.apolloInitialErrorStatus',
                  global.apolloInitialErrorStatus,
                ],
                'green',
              );

              const cache = generateApolloCache(state);
              isHydrated = true;

              resolve(cache);
            }
          });
        }

        // hydrate apollo BE error state apolloInlineErrorState
        if (__CLIENT__ && window.__APOLLO_STATE__) {
          const initialState = JSON.parse(window.__APOLLO_STATE__);
          global.apolloInitialErrorStatus = initialState.statusCode;
          state = initialState.state;
          const cache = generateApolloCache(state);
          resolve(cache);
        }
      });
    }, 0);
  });

const apolloClient: any = async (
  apiUri: string,
  apiOrigin: string,
  host: string,
  generateApolloCache,
  fetchAfterware: () => any | null = null,
  headers = {},
) => {
  const cache = await initCache({ generateApolloCache });

  // create client
  return new ApolloClient({
    ssrMode: false,
    ssrForceFetchDelay: 0,
    connectToDevTools,
    link: rasApolloLinks({
      uri: apiUri,
      apiOrigin,
      host,
      fetchAfterware,
      headers,
    }),
    cache,
  });
};

export default apolloClient;
