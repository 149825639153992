import React from 'react';
import { SocialIconProps } from '../../typings';

const InstagramIcon = ({
  className,
  classNameGradient,
  classNameGradientInner,
  id,
}: Omit<SocialIconProps, 'type'>) => (
  <svg width="22" height="22" className={className}>
    <g fill={`url(#${id})`} className={classNameGradient}>
      <g className={classNameGradientInner}>
        <path d="M14.24 5.837H7.7c-1.043 0-1.892.85-1.892 1.893v6.54c0 1.043.85 1.893 1.893 1.893h6.54c1.044 0 1.893-.85 1.893-1.893V7.73c0-1.043-.85-1.893-1.893-1.893zM10.97 14.4c-1.874 0-3.4-1.526-3.4-3.4 0-1.874 1.526-3.4 3.4-3.4 1.875 0 3.4 1.526 3.4 3.4 0 1.874-1.525 3.4-3.4 3.4zm3.51-6.096c-.445 0-.806-.36-.806-.804 0-.444.36-.805.805-.805.442 0 .803.36.803.805 0 .443-.36.804-.804.804z" />
        <path d="M10.97 9.037c-1.08 0-1.962.88-1.962 1.963 0 1.082.88 1.963 1.962 1.963 1.083 0 1.963-.88 1.963-1.963 0-1.082-.88-1.963-1.963-1.963z" />
        <path d="M10.97 0C4.912 0 0 4.912 0 10.97c0 6.06 4.912 10.97 10.97 10.97 6.06 0 10.97-4.91 10.97-10.97C21.94 4.912 17.03 0 10.97 0zm6.6 14.27c0 1.836-1.494 3.33-3.33 3.33H7.7c-1.835 0-3.33-1.494-3.33-3.33V7.73c0-1.835 1.495-3.33 3.33-3.33h6.54c1.836 0 3.33 1.495 3.33 3.33v6.54z" />
      </g>
    </g>
  </svg>
);
export default InstagramIcon;
