/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { PUBLICATION_ID_DE, PUBLICATION_ID_FR } from '../../constants';
import { GET_AUTHORS_PAGE } from './queries';
import { RasRouterProps } from '../../components/Router/typings';

export const apolloConfig: RaschApolloConfig<RasRouterProps> = {
  options: ({ location, props }) => {
    const { language } = props;
    const path = location?.pathname?.substr(1);
    return {
      query: GET_AUTHORS_PAGE,
      variables: {
        path,
        publication: language === 'fr' ? PUBLICATION_ID_FR : PUBLICATION_ID_DE,
      },
    };
  },
};
