import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import settingsStateSelector from '../../../../../../shared/selectors/settingsStateSelector';
import TeaserAuthor from '../../../../components/Teaser/components/TeaserAuthor';
import { DEFAULT_LANGUAGE } from '../../../../components/Navigation/components/LanguageSwitch';
import {
  PUBLICATION_GM,
  PUBLICATION_GM_FR,
} from '../../../../../../../shared/constants/publications';
import styles from './styles.legacy.css';
import { AuthorsTeaserProps } from './typings';

const AuthorsTeaser = ({ authors }: AuthorsTeaserProps) => {
  const language = useSelector(
    (state) => settingsStateSelector(state).language,
  );

  if (!authors || authors.length <= 0) {
    return null;
  }

  const readMoreLable: any = (
    <FormattedMessage
      id="app.authorTeaser.readMore"
      description="The read more label on the author teaser"
      defaultMessage="Mehr erfahren"
    />
  );
  // En savoir plus

  const publication =
    language === DEFAULT_LANGUAGE ? PUBLICATION_GM : PUBLICATION_GM_FR;

  return (
    <div className={styles.AuthorsWrapper}>
      {authors
        .filter(
          ({ node: { hasProfilePage, preferredUri, publications } }) =>
            hasProfilePage &&
            preferredUri &&
            publications.includes(publication),
        )
        .map(({ node: author }) => (
          <div key={author.id}>
            <div className={styles.Divider} />
            <TeaserAuthor
              author={author}
              insideArticle
              withAuthorImage
              readMoreLabel={readMoreLable}
            />
          </div>
        ))}
      <div className={styles.Divider} />
    </div>
  );
};

export default AuthorsTeaser;
