/**
 * @file   content type constants
 */

export const AUTHOR_CONTENT_TYPE = 'Author';
export const ARTICLE_CONTENT_TYPE = 'Article';
export const BRANCH_CONTENT_TYPE = 'Branch';
export const CHANNEL_CONTENT_TYPE = 'Channel';
export const DOSSIER_CONTENT_TYPE = 'Dossier';
export const EXPLAINING_ARTICLE_CONTENT_TYPE = 'ExplainingArticle';
export const IMAGE_GALLERY_CONTENT_TYPE = 'ImageGallery';
export const KEYWORD_CONTENT_TYPE = 'Keyword';
export const KEYWORD_SETTINGS_CONTENT_TYPE = 'KeywordSettings';
export const LANDING_PAGE_CONTENT_TYPE = 'LandingPage';
export const NATIVE_ADVERTISING_CONTENT_TYPE = 'NativeAdvertising';
export const NATIVE_ADVERTISING_CONTENT_TYPE_LABEL = 'Native Advertising';
export const ORGANIZATION_CONTENT_TYPE = 'Organization';
export const PAGE_CONTENT_TYPE = 'Page';
export const PERSON_CONTENT_TYPE = 'Person';
export const PRODUCT_CONTENT_TYPE = 'Product';
export const RANKING_CONTENT_TYPE = 'Ranking';
export const RECIPE_CONTENT_TYPE = 'Recipe';
export const SPONSOR_CONTENT_TYPE = 'Sponsor';
export const TEASER_CONTENT_TYPE = 'Teaser';
export const VIDEO_CONTENT_TYPE = 'Video';
export const CONTENT_BOX_TYPE = 'ContentBox';
export const TOPIC_CONTENT_TYPE = 'Topic';
export const WIDGET_CONTENT_TYPE = 'Widget';

export const ARTICLE_TYPE_ASSOCIATION = 'association';
export const ARTICLE_TYPE_ASSOCIATION_LABEL = 'Verbandsbeitrag';

export const ARTICLE_TYPE_GUIDE = 'guide';
export const ARTICLE_TYPE_GUIDE_FALLBACK_LABEL = 'Ratgeber';
export const ARTICLE_TYPE_HEADLESS = 'headless';
export const ARTICLE_TYPE_HOT_TEN = 'hot_ten';
export const ARTICLE_TYPE_INTERVIEW = 'interview';
export const ARTICLE_TYPE_JOURNALISTIC = 'journalistic';
export const ARTICLE_TYPE_LISTICAL_ASC = 'listicle_asc';
export const ARTICLE_TYPE_LISTICAL_DESC = 'listicle_desc';
export const ARTICLE_TYPE_LONG_READ = 'longread';
export const ARTICLE_TYPE_LONG_READ_LABEL = 'Long Read';
export const ARTICLE_TYPE_NEWS = 'news';
export const ARTICLE_TYPE_TICKER = 'ticker';
export const ARTICLE_TYPE_OPINION = 'opinion';
export const ARTICLE_TYPE_SPONSORED = 'sponsored_article';
export const ARTICLE_TYPE_LONG_FORM = 'longform';
export const ARTICLE_TYPE_OPINION_LABEL = 'Kommentar';
export const ARTICLE_TYPE_RATGEBER = 'ratgeber_aktuell';
export const ARTICLE_TYPE_RESTAURANT = 'restaurant';
export const ARTICLE_TYPE_SEATCHANGE = 'seatchange';
export const ARTICLE_TYPE_SEATCHANGE_LABEL = 'Sesselwechsel';
export const ARTICLE_TYPE_SEATCHANGE_LABEL_FR = 'Trajectoire';
export const ARTICLE_TYPE_TESTIMONIAL = 'testimonial';
export const ARTICLE_TYPE_LAW_UPDATE = 'law-update';
export const LONG_READ_ARTICLE_PAGE_TYPE = 'article-long-read';
export const FINANCE_DICTIONARY_TYPE = 'ExplainingArticleFinanceDictionary';
export const INFOBOX_COMPONENT_GUIDER = 'InfoboxGuider';
export const LEXICON_TYPE = 'Lexicon';
export const ARTICLE_TYPE_RATGEBER_BEO = 'guide';
export const ARTICLE_TYPE_LEGAL_ADVICE_GRUNDLAGE = 'comment';
export const ARTICLE_TYPE_LEGAL_ADVICE_HILFSMITTEL = 'tool';

export const ADVERTISING_TYPE_ADVERTORIAL = 'advertorial';
export const ADVERTISING_TYPE_ADVERTORIAL_LABEL = 'Publireportage';
export const ADVERTISING_TYPE_EXTERNAL = 'external';
export const ADVERTISING_TYPE_EXTERNAL_LABEL = 'External Ad';
export const ADVERTISING_TYPE_NATIVE_ARTICLE = 'native_article';
export const ADVERTISING_TYPE_NATIVE_ARTICLE_LABEL = 'Native Article';
export const ADVERTISING_TYPE_SPONSORING = 'sponsoring';
export const ADVERTISING_TYPE_SPONSORING_LABEL = 'Sponsoring';
export const ADVERTISING_TYPE_BRANDREPORT = 'brandreport';
export const ADVERTISING_TYPE_LONGFORM = 'longform';
export const ADVERTISING_TYPE_LONGFORM_LABEL = 'Longform';
export const ADVERTISING_TYPE_BRANDREPORT_LABEL = 'BrandReport';

export const RESTRICTION_STATUS_NONE = null;
export const RESTRICTION_STATUS_PAID = 'paid';
export const RESTRICTION_STATUS_REGISTERED = 'registered';

export const CONTENT_SOURCE_MOST_READ = 'most_read';
export const CONTENT_SOURCE_MOST_SHARED = 'most_shared';
export const CONTENT_SOURCE_MOST_COMMENTED = 'most_commented';
export const CONTENT_SOURCE_MANUAL = 'manual';
export const CONTENT_SOURCE_MANUAL_RANDOM = 'manual_random';
export const CONTENT_SOURCE_TICKER = 'ticker';
export const CONTENT_SOURCE_TABS = 'tabs';
export const CONTENT_SOURCE_NEWEST = 'newest';
export const CONTENT_BOX_STYLE_NUMBERED_LIST = 'numbered_list';

export const CHANNEL_TYPE_VIDEO_BLOG = 'video_blog';
export const CHANNEL_TYPE_BLOG = 'blog';
export const CHANNEL_TYPE_SPECIAL = 'special';
export const CHANNEL_TYPE_SPECIAL_LABEL = 'Special';

export const LANDING_PAGE_TYPE_HOME = 'landing-page-home';

export const PAGE_TYPE_MARKETING = 'marketing_page';
export const PAGE_TYPE_MARKETING_DEFAULT_HEADER =
  'marketing_page_default_header';
export const PAGE_TYPE_SPONSOR = 'Sponsor';

export const TEASER_TYPE_TILE = 'tile';
export const WIDGET_TYPE_CHART = 'chart';
export const WIDGET_TYPE_FORUM = 'forum';
export const WIDGET_TYPE_TRENDRADAR = 'trendradar';
export const WIDGET_TYPE_EXTENDED_CHART = 'extendedCharts';

export const ANCHOR_TITLE = 'anchor_tag_title';
export const ANCHOR_SHORT_TITLE = 'anchor_tag_short_title';
export const ANCHOR_HERO = 'anchor_tag_hero';
