/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import {
  GLOBAL_SEARCH_FILTER_ARTICLE,
  GLOBAL_SEARCH_SORT_BY_PUBLICATION_DATE,
} from '../../../../../shared/constants/globalSearch';
import {
  ARTICLE_TYPE_BLOG_A,
  PUBLICATION_ID_DE,
  PUBLICATION_ID_FR,
} from '../../constants';
import { PAGE_SIZE } from './constants';
import { GET_ARTICLE_BLOG_A } from './queries';
import { BlogAProps } from './typings';

export const apolloConfig: RaschApolloConfig<BlogAProps> = {
  options: ({ location, props: { language } }) => {
    const page = location?.query?.page || 1;
    return {
      query: GET_ARTICLE_BLOG_A,
      variables: {
        path: location.pathname.substr(1),
        publication: language === 'fr' ? PUBLICATION_ID_FR : PUBLICATION_ID_DE,
        query: '',
        pageSize: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
        filter: GLOBAL_SEARCH_FILTER_ARTICLE,
        articleType: ARTICLE_TYPE_BLOG_A,
        sort: GLOBAL_SEARCH_SORT_BY_PUBLICATION_DATE,
      },
    };
  },
};
