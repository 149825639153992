import React, { ReactElement } from 'react';
import styles from './styles.legacy.css';
import { LazyLoadingProps } from './typings';

type LazyLoadingPropsInner = LazyLoadingProps;

const LazyLoading = (props: LazyLoadingPropsInner): ReactElement =>
  props.hasMoreResults && (
    <button
      onClick={!props.IsLoading ? props.loadMore : null}
      className={props.className || `lazy-Loading-btn ${styles.LoadMore}`}
    >
      {props.children || <span>Mehr Stichworte laden</span>}
    </button>
  );

export default LazyLoading;
