import React from 'react';
import { IntlFormatters, defineMessages, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import brightcoveFactory from '../../../../../common/components/Brightcove/factory';
import { createSSRHelmet } from '../../../../../common/components/Brightcove/helpers';
import {
  assembleAkamaiImgUrl,
  getWidthAndHeightByImageStyle,
} from '../../../../../common/components/Picture/helpers';
import locationStateSelector from '../../../../../shared/selectors/locationStateSelector';
import CSSPicture from '../../../../../common/components/CSSPicture';
import { useSSRContext } from '../../../../../common/components/SSRContext';
import {
  STYLE_HEADER_16_9_LARGE,
  STYLE_HEADER_16_9_SMALL,
  STYLE_THUMBNAIL,
} from '../../../../../shared/constants/images';
import {
  BRIGHTCOVE_ACCOUNT_ID,
  BRIGHTCOVE_PLAYER_ID,
} from '../../../App/constants';
import styles from './styles.legacy.css';
import type { BrightcoveProps } from '../../../../../common/components/Brightcove/typings';

export type VideoPlayerPropsInner = BrightcoveProps & {
  intl: IntlFormatters;
};

const msg: Record<string, any> = defineMessages({
  overlayAdLabel: {
    id: 'app.video.overlayAdLabel',
    description: 'Video overlay ad label',
    defaultMessage: 'Anzeige',
  },
  skipButtonLabel: {
    id: 'app.video.skipAdLabel',
    description: 'Video skip ad label',
    defaultMessage: 'Werbung überspringen',
  },
  skipButtonDelayLabel: {
    id: 'app.video.skipAdDelayLabel',
    description: 'Video skip ad delay label',
    defaultMessage: 'Werbung in %s Sek. überspringen',
  },
});

const getOverlayLabelByProps = ({ overlayAdLabel }): string => overlayAdLabel;
const getSkipButtonLabelByProps = ({ skipButtonLabel }): string =>
  skipButtonLabel;
const getSkipButtonDelayLabelByProps = ({ skipButtonDelayLabel }): string =>
  skipButtonDelayLabel;

const Player = brightcoveFactory({
  accountId: BRIGHTCOVE_ACCOUNT_ID,
  playerId: BRIGHTCOVE_PLAYER_ID,
  overlayAdLabel: getOverlayLabelByProps,
  skipButtonLabel: getSkipButtonLabelByProps,
  skipButtonDelayLabel: getSkipButtonDelayLabelByProps,
});

const Video = ({
  video,
  autoPlay = false,
  muted = false,
  isObserveForAutoplayEnabled = false,
  hasToLazyLoadBrightcoveScript = true,
  intl,
}: VideoPlayerPropsInner) => {
  const { isSSR } = useSSRContext();
  const clientUrl = useSelector(
    (state) => locationStateSelector(state).clientUrl,
  );

  if (!video) {
    return null;
  }

  const relativeOriginPath =
    video?.image?.file?.relativeOriginPath ||
    video?.teaserImage?.image?.file?.relativeOriginPath ||
    '';

  const { width: coverImageWidth, height: coverImageHeight } =
    getWidthAndHeightByImageStyle(STYLE_HEADER_16_9_LARGE);

  const { width: thumbnailWidth, height: thumbnailHeight } =
    getWidthAndHeightByImageStyle(STYLE_THUMBNAIL);

  return (
    <div
      key={`video-${video.brightcoveId}`}
      data-testid="video-container"
      className={styles.Wrapper}
    >
      {createSSRHelmet(
        video,
        assembleAkamaiImgUrl({
          relativeOriginPath,
          width: coverImageWidth,
          height: coverImageHeight,
          focalPointX: video?.image?.file?.focalPointX,
          focalPointY: video?.image?.file?.focalPointY,
          clientUrl,
        }),
        assembleAkamaiImgUrl({
          relativeOriginPath,
          width: thumbnailWidth,
          height: thumbnailHeight,
          focalPointX: video?.image?.file?.focalPointX,
          focalPointY: video?.image?.file?.focalPointY,
          clientUrl,
        }),
      )}

      {(!__TESTING__ && !isSSR && video.brightcoveId && (
        <Player
          video={video}
          autoPlay={autoPlay}
          muted={muted}
          isObserveForAutoplayEnabled={isObserveForAutoplayEnabled}
          hasToLazyLoadBrightcoveScript={hasToLazyLoadBrightcoveScript}
          overlayAdLabel={intl.formatMessage(msg.overlayAdLabel)}
          skipButtonLabel={intl.formatMessage(msg.skipButtonLabel)}
          skipButtonDelayLabel={intl.formatMessage(msg.skipButtonDelayLabel)}
        />
      )) || (
        <CSSPicture
          style_320={STYLE_HEADER_16_9_SMALL}
          style_540={STYLE_HEADER_16_9_LARGE}
          relativeOriginPath={relativeOriginPath}
        >
          {({ className }) => {
            return (
              <div
                data-testid="video-ssr-loading-container"
                className={classNames(styles.LoaderWrapper, className)}
              >
                <span className={styles.Loader}>loading...</span>
              </div>
            );
          }}
        </CSSPicture>
      )}
    </div>
  );
};

export default compose(injectIntl)(Video);
