/* istanbul ignore file */

import { compose } from 'redux';
import { STRUCTURED_META_DATA } from '../helpers/metaData';
import withHelmetFactory from '../../../shared/decorators/withHelmetFactory';
import Helmet from '../../screens/App/components/Helmet';
import { WithHelmet } from './withHelmet';
import { PUBLICATION_GM_SEO_TITLE as PUBLISHER } from '../../../shared/constants/publications';
import gmLogo from '../../screens/App/assets/graphics/logo-gaultmillau.png';
export { generateMetaLinks } from '../../../shared/helpers/withHelmet';

export type {
  WithHelmetProps,
  WithHelmet,
  WithHelmetNodeProps,
} from '../../../shared/decorators/@types/withHelmetFactory';

const withHelmet = withHelmetFactory({
  Helmet,
  getPublisher: () => PUBLISHER,
  getPublisherLogo: () => `${global.locationOrigin}${gmLogo}`,
});

const WithHelmetComponent = (props: WithHelmet) =>
  compose(
    withHelmet({
      ...props,
      structuredDefaultData: STRUCTURED_META_DATA,
    }),
  );

export default WithHelmetComponent;
