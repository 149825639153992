/**
 * Grid Layouts
 */
export const GRID_LAYOUT_HERO_MIXED = `heroMixed`;
export const GRID_LAYOUT_MIXED = `mixed`;
export const GRID_LAYOUT_MIXED_WITH_AD = `mixedWithAd`;
export const GRID_LAYOUT_TEASER_3X2 = 'teaser3x2';
export const GRID_LAYOUT_TEASER_3X4 = 'teaser3x4';
export const GRID_LAYOUT_TEASER_3X6 = 'teaser3x6';
export const GRID_LAYOUT_TEASER_2X2 = 'teaser2x2';
export const GRID_LAYOUT_TEASER_3X3 = 'teaser3x3';
export const GRID_LAYOUT_TEASER_2X5 = 'teaser2x5';
export const GRID_LAYOUT_HERO_SLIDER = 'heroSlider';
export const GRID_LAYOUT_HOME_TOP = 'homeTop';
export const GRID_LAYOUT_HOME_BOTTOM = 'homeBottom';

export const TYPE_HEROSLIDER_HOME = 'heroSliderHome';
export const TYPE_DIVIDER = 'grid-divider';
