import React from 'react';
import Picture from '../../../../../Picture';
import { STYLE_1X1_140 } from '../../../../../../../shared/constants/images';

interface AuthorPictureProps {
  imageParagraph: ImageParagraph;
  name: string;
  className?: string;
}

const AuthorPicture = ({
  imageParagraph,
  name,
  className = '',
}: AuthorPictureProps) => {
  const imageFile = imageParagraph?.image?.file || null;
  const focalPointX = imageParagraph?.image?.file.focalPointX || null;
  const focalPointY = imageParagraph?.image?.file.focalPointY || null;

  return (
    <Picture
      relativeOrigin={imageFile?.relativeOriginPath}
      alt={imageFile?.alt || name}
      className={className}
      style_320={STYLE_1X1_140}
      focalPointX={focalPointX}
      focalPointY={focalPointY}
    />
  );
};

export default AuthorPicture;
