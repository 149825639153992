/**
 * @file   Selectfield constants
 * @author Serkan Ucmak <serkan.ucmak@ringieraxelspringer.ch>
 * @date   2018-08-15
 */

export const HIDE_PLACEHOLDER_FROM_OPTIONS_ALL =
  'hide-placeholder-from-options/all';
export const HIDE_PLACEHOLDER_FROM_OPTIONS_DESKTOP_ONLY =
  'hide-placeholder-from-options/desktop-only';
export const HIDE_PLACEHOLDER_FROM_OPTIONS_MOBILE_ONLY =
  'hide-placeholder-from-options/mobile-only';
