/**
 * @file   returns app based query map for frontend
 * @author Roman Zanettin <roman.zanettin@ringieraxelspringer.ch>
 * @date   2017-11-16
 */

import {
  apiVersion as beoApiVersion,
  default as beoQueryMap,
} from '../beobachter/shared/queries-frontend';
import {
  apiVersion as cashApiVersion,
  default as cashQueryMap,
} from '../cash/shared/queries-frontend';
import {
  apiVersion as gmApiVersion,
  default as gmQueryMap,
} from '../gaultmillau/shared/queries-frontend';
import {
  apiVersion as hzApiVersion,
  default as hzQueryMap,
} from '../handelszeitung/shared/queries-frontend';
import {
  apiVersion as ileApiVersion,
  default as ileQueryMap,
} from '../illustre/shared/queries-frontend';
import {
  apiVersion as pmeApiVersion,
  default as pmeQueryMap,
} from '../pme/shared/queries-frontend';
import {
  apiVersion as siApiVersion,
  default as siQueryMap,
} from '../schweizer-illustrierte/shared/queries-frontend';
import {
  apiVersion as teApiVersion,
  default as teQueryMap,
} from '../tele/shared/queries-frontend';

const getQueryMapByAppName: Function = (): Object => {
  switch (__APP_NAME__) {
    case 'beobachter': {
      return { map: beoQueryMap, version: beoApiVersion };
    }
    case 'cash': {
      return { map: cashQueryMap, version: cashApiVersion };
    }
    case 'gaultmillau': {
      return { map: gmQueryMap, version: gmApiVersion };
    }
    case 'handelszeitung': {
      return { map: hzQueryMap, version: hzApiVersion };
    }
    case 'schweizer-illustrierte': {
      return { map: siQueryMap, version: siApiVersion };
    }
    case 'illustre': {
      return { map: ileQueryMap, version: ileApiVersion };
    }
    case 'pme': {
      return { map: pmeQueryMap, version: pmeApiVersion };
    }
    case 'tele': {
      return { map: teQueryMap, version: teApiVersion };
    }
    default:
      return {};
  }
};
export default getQueryMapByAppName();
