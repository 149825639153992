import React, { Suspense, lazy } from 'react';
import imageGalleryParagraphFactory from '../../../../../../../common/components/Paragraphs/components/ImageGalleryParagraph/factory';
import SuspenseFallback from '../../../../../../../common/components/SuspenseFallback';
// eslint-disable-next-line postcss-modules/no-unused-class
import styles from '../../../ImageGallery/styles.legacy.css';

const ImageGalleryFinal = lazy(
  () => import(/* webpackChunkName: "ImageGallery" */ '../../../ImageGallery'),
);

const ImageGalleryParagraph = imageGalleryParagraphFactory({
  ImageGallery: (props) => (
    <Suspense fallback={<SuspenseFallback />}>
      <ImageGalleryFinal {...props} styles={styles} />
    </Suspense>
  ),
});

export default ImageGalleryParagraph;
