import React, { memo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import urlMod from 'url';
import { isFileLink } from '../../../shared/helpers/utils';
import {
  getDomain,
  getLinkRel,
  getLinkTarget,
  isSpecialProtocol,
} from './helpers';
import { useSrollToLinkElement } from '../../../shared/hooks/useScrollToLinkElement';
import { LinkProps } from './typings';

type LinkPropsInner = LinkProps;

const Link = ({
  path,
  rel,
  children,
  trackingData,
  className,
  ariaLabel,
  onClick,
  label,
  title,
}: LinkPropsInner) => {
  const trackingDataString = JSON.stringify(trackingData);
  const { pushToLinkStack } = useSrollToLinkElement();

  if ((!path || typeof path.startsWith !== 'function') && !onClick) {
    return (
      <div
        className={className}
        data-testid="empty-link"
        data-track-info={trackingDataString}
      >
        {children || label}
      </div>
    );
  }

  const { isSameDomain } = getDomain(path);

  const customClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isSameDomain || path?.startsWith('/')) {
      pushToLinkStack(event.target as HTMLElement);
    }
    if (onClick) {
      return onClick(event);
    }
    return null;
  };

  // Render routed link
  if (
    path &&
    (!isSpecialProtocol(path) ||
      (path.startsWith('http') && isSameDomain && !isFileLink(path)))
  ) {
    let parsedPath = path;

    // make relative path if we are on same host
    if (path.startsWith('http')) {
      const parsedUri = urlMod.parse(path);
      parsedPath = `${parsedUri.path}${parsedUri?.hash || ''}`;
    }
    return (
      <ReactRouterLink
        data-testid="routed-link"
        data-track-info={trackingDataString}
        rel={rel}
        to={parsedPath}
        className={className}
        aria-label={ariaLabel}
        title={title}
        onClick={customClick}
      >
        {children || label}
      </ReactRouterLink>
    );
  }

  const linkRel = getLinkRel(path, rel);
  const target = getLinkTarget(path);

  return (
    <a
      data-testid="default-link"
      data-track-info={trackingDataString}
      target={target}
      rel={linkRel}
      href={path}
      className={className}
      aria-label={ariaLabel}
      onClick={customClick}
      title={title}
    >
      {children || label}
    </a>
  );
};

export default memo<LinkProps>(Link);
