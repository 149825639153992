export const TEASER_TITLE_LENGTH = 49;
export const TEASER_LEAD_LENGTH = 114;

export const TEASER_HERO_MAIN_IDENTIFIER = 'teaser-hero-main';
export const TEASER_HERO_A_IDENTIFIER = 'teaser-hero-a';
export const TEASER_HERO_B_IDENTIFIER = 'teaser-hero-b';
export const TEASER_ML_IDENTIFIER = 'teaser-m-l';
export const TEASER_RECIPE_IDENTIFIER = 'teaser-recipe';
export const TEASER_PRODUCT_DEFAULT_IDENTIFIER = 'teaser-product-default';
export const TEASER_PRODUCT_FULL_WIDTH_IDENTIFIER = 'teaser-product-full-width';

export const TEASER_ORIGIN_HOME_SLIDER = 'origin/home-slider';
