export const AUTH0_LOCAL_STORAGE_STATE = 'auth0-state';
export const AUTH0_LOCAL_STORAGE_ACCESS_TOKEN = 'auth0-access-token';
export const AUTH0_LOCAL_STORAGE_NONCE = 'auth0-nonce';
export const AUTH0_LOCAL_STORAGE_ID_TOKEN = 'auth0-id-token';

export const AUTH0_LOGIN_CASE_GENERAL = 'general';
export const AUTH0_LOGIN_CASE_COMMENTING = 'commenting';
export const AUTH0_LOGIN_CASE_CUSTOM_SCROLLTO_ANCHOR = 'custom-scrollto-anchor';
export const AUTH0_LOGIN_CASE_EMAIL_ONLY = 'email_only';
export const AUTH0_LOGIN_CASE_LEAN_REGISTRATION = 'lean_registration';

export const AUTH0_CHECK_SESSION_ERROR_LOGIN_REQUIRED = 'login_required';

export const AUTH0_USER_ID = 'auth0-user-id';
export const RC_SUB = 'rc:sub';
export const RC_EMAIL = 'rc:email';

export const AUTH0_USER_METADATA = 'user_metadata';
export const AUTH0_APP_METADATA = 'app_metadata';
