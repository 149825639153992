import React from 'react';

const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="28"
    viewBox="0 0 20 28"
    {...props}
  >
    <path d="M0.32532556,7.68969915 C-0.326508462,10.1227602 0.00800913675,12.6641584 1.26746714,14.84569 C2.94714685,17.7549519 6.07957145,19.5622825 9.44225039,19.5622825 C10.7113652,19.5622825 11.9688617,19.301398 13.1350712,18.8033183 L18.0399713,27.2987375 L20,26.1670812 L15.094949,17.6713603 C16.780966,16.4109969 17.9904802,14.6359563 18.5436338,12.5710608 C19.1954678,10.1379998 18.8609502,7.59660156 17.6014922,5.41507001 C15.9219634,2.50595892 12.7895388,0.698628382 9.42685986,0.698628382 C7.78037541,0.698628382 6.152601,1.13605126 4.71932059,1.96351834 C2.53778905,3.22297634 0.977461356,5.25663813 0.32532556,7.68969915 Z M9.42685986,2.96194096 C11.9834977,2.96194096 14.3649552,4.3356208 15.6416144,6.54687719 C16.5988447,8.20482909 16.8530902,10.13634 16.3575756,11.9854664 C15.862212,13.8345928 14.6762362,15.3802844 13.0182843,16.3373638 C11.9285747,16.9664138 10.6922025,17.2989699 9.44240127,17.2989699 C6.88576339,17.2989699 4.5043059,15.9252901 3.22764672,13.7140337 C2.27041639,12.0560818 2.01617094,10.1247217 2.51168551,8.27559533 C3.00720007,6.42631807 4.19302498,4.88077736 5.85097688,3.92354703 C6.94068644,3.29449702 8.17705866,2.96194096 9.42685986,2.96194096 Z" />
  </svg>
);

export default SearchIcon;
