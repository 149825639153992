import type { NavigationDefaultProps } from './typings';

// ---------------------------------------------------------------------------------- //
// COMPONENT
// ---------------------------------------------------------------------------------- //

const NavigationDefault = ({
  navigationPrimaryMenu,
  navigationPrimaryMenuFr,
  navigationSecondaryMenu,
  renderNavigationOverlay,
}: NavigationDefaultProps) => {
  return renderNavigationOverlay(
    navigationPrimaryMenu,
    navigationPrimaryMenuFr,
    navigationSecondaryMenu,
  );
};

export default NavigationDefault;
