import React from 'react';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { ensureTeaserInterface } from '../../../../components/Teaser/shared/helpers';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import EditButtons from '../../../../components/EditButtons';
import Paragraphs from '../../../../components/Paragraphs';
import TeaserGrid from '../../../../components/TeaserGrid';
import Lead from '../Lead';
import {
  PAGER_TYPE_PAGE_LOADER,
  default as Pager,
} from '../../../../components/Pager';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../../../../src/shared/constants/structuredData';
import {
  GRID_LAYOUT_MIXED,
  GRID_LAYOUT_MIXED_WITH_AD,
} from '../../../../components/TeaserGrid/gridConfigs/constants';
import { LANDING_PAGE_GRID_ITEMS, LANDING_PAGE_TYPE } from '../../constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { LandingPageProps } from '../../typings';

export const hasGridItems = (landingPage: LandingPage) =>
  landingPage.grid &&
  landingPage.grid.edges &&
  landingPage.grid.edges.length > 0;

const LandingPage = ({ landingPage, page }: LandingPageProps) => {
  // life and style page and news page is sold so we have to add ad slots to show the partner
  const gridLayout =
    landingPage?.title === 'Life & Style' || landingPage?.title === 'Les news'
      ? GRID_LAYOUT_MIXED_WITH_AD
      : GRID_LAYOUT_MIXED;

  return (
    <div className={classNames('landing-page', styles.Wrapper)}>
      <EditButtons
        editContentUri={landingPage?.editContentUri}
        editRelationUri={landingPage?.editRelationUri}
        cloneContentUri={landingPage?.cloneContentUri}
      />

      <div className={grid.Container}>
        <div className={grid.Row}>
          <div className={grid.ColXs24}>
            <Lead landingPage={landingPage} />
          </div>
        </div>
      </div>

      <Paragraphs
        pageBody={landingPage.body}
        page={page}
        colStyle={grid.ColXs24}
        origin={LANDING_PAGE_TYPE}
      />

      {hasGridItems(landingPage) && (
        <>
          <TeaserGrid
            items={ensureTeaserInterface(landingPage.grid?.edges)}
            layout={gridLayout}
          />
          <div className={grid.Container}>
            <Pager
              itemsCount={landingPage.grid.count || 0}
              itemsPerPage={LANDING_PAGE_GRID_ITEMS}
              currentPage={page}
              component={PAGER_TYPE_PAGE_LOADER}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default compose(
  withHelmet({
    getNode: (mapProps) => mapProps.landingPage,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodesCount: (mapProps) => mapProps?.landingPage?.grid?.count || 0,
    pageSize: LANDING_PAGE_GRID_ITEMS,
    getNodes: (mapProps) => mapProps?.landingPage?.grid?.edges || [],
    hasBreadcrumbs: () => false,
  }),
)(LandingPage);
