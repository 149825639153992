import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import {
  assembleAkamaiImgUrl,
  getWidthAndHeightByImageStyle,
} from '../../../../../../../common/components/Picture/helpers';
import { formatPrice } from '../../../../../../../shared/helpers/utils';
import { getFallbackTitle } from '../../helpers';
import locationStateSelector from '../../../../../../../shared/selectors/locationStateSelector';
import withHeaderProps from '../../../../../../shared/decorators/withHeaderProps';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import {
  resetHeaderData,
  setHeaderData,
} from '../../../../../../shared/actions/header';
import { VIEWPORT_XS } from '../../../../../../shared/actions/window';
import Link from '../../../../../../../common/components/Link';
import EditButtons from '../../../../components/EditButtons';
import Helmet from '../../../../components/Helmet';
import Hero from '../../../../components/Hero';
import RelatedContent from '../../../../components/RelatedContent';
import TabsTwoCols from '../../../../components/TabsTwoCols';
import UtilityBar from '../../../../components/UtilityBar';
import UtilityOverlay from '../../../../components/UtilityBar/components/UtilityOverlay';
import OrganizationShare from '../../components/OrganizationShare';
import RestaurantRanking, {
  RESTAURANT_RANKING_ORIGIN_RESTAURANT,
} from '../../../Organization/components/RestaurantRanking';
import sponsorImageFactory, {
  SPONSOR_IMAGE_POSITION_INLINE,
} from '../../../../components/SponsorImage';
import { DEFAULT_LANGUAGE } from '../../../../components/Navigation/components/LanguageSwitch';
import { useSSRContext } from '../../../../../../../common/components/SSRContext';
import { ANCHOR_TITLE } from '../../../../../../../shared/constants/content';
import { STYLE_TEASER_1_1 } from '../../../../../../../shared/constants/images';
import { ROOT_SCHEMA_TYPE_NEWS_ARTICLE } from '../../../../../../../shared/constants/structuredData';
import {
  UTILITY_BAR_SHARE_LABEL,
  UTILITY_BAR_SHARE_LABEL_FR,
} from '../../../../../../../shared/constants/utilitybar';
import { GRID_LAYOUT_TEASER_3X2 } from '../../../../components/TeaserGrid/gridConfigs/constants';
import {
  UTILITYBAR_CONFIG,
  UTILITYBAR_OVERLAY_CONFIG,
} from '../../../../components/UtilityBar/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';

const CATEGORY = 'Restaurant';

const organizationColStyle = classNames(
  grid.ColSm20,
  grid.ColOffsetSm2,
  grid.ColXl14,
  grid.ColOffsetXl5,
);

const msgs = defineMessages({
  hasTerraceOrGarden: {
    id: 'organization.restaurant.service.terraceOrGarden',
    description: 'The default label for service terrace or garden',
    defaultMessage: 'Terrasse/Garten',
  },
  hasParkingAvailable: {
    id: 'organization.restaurant.service.parking',
    description: 'The default label for service parking',
    defaultMessage: 'Parking',
  },
  hasWheelchairAccess: {
    id: 'organization.restaurant.service.wheelchairAccess',
    description: 'The default label for service wheelchair access',
    defaultMessage: 'Rollstuhlgängig',
  },
});

const getWebSite = (webSite) =>
  (webSite.substring(0, 4) === 'http' && webSite) || `http://${webSite}`;

const generateServices = (organization, intl) => {
  const availableServices = Object.keys(msgs).filter(
    (key) => organization.organizationData[key],
  );
  if (availableServices.length > 0) {
    return availableServices
      .map((key) => intl.formatMessage(msgs[key]))
      .join(', ');
  }
  return null;
};

const SponsorImage = sponsorImageFactory({
  position: SPONSOR_IMAGE_POSITION_INLINE,
});

const OrganizationRestaurant = ({
  organization,
  intl,
  language,
  setHeaderData,
  resetHeaderData,
  clientUrl,
}) => {
  const { isSSR } = useSSRContext();
  const {
    gcid,
    subtypeValue = '',
    channel,
    commentStatus,
    preferredUri,
    socialMediaTitle,
    title,
    shortTitle,
    description,
    __typename,
  } = organization;
  useEffect(() => {
    setHeaderData({
      articleData: {
        gcid,
        title,
        shortTitle,
        lead: description,
        subtypeValue,
        channel,
        commentStatus,
        preferredUri,
        socialMediaTitle,
      },
      contentType: __typename,
    });
    return () => {
      resetHeaderData();
    };
  }, [
    __typename,
    gcid,
    channel,
    commentStatus,
    preferredUri,
    resetHeaderData,
    setHeaderData,
    socialMediaTitle,
    subtypeValue,
    title,
    shortTitle,
    description,
  ]);

  if (!organization?.organizationData) {
    return null;
  }

  const metaLinksRestaurant = [
    {
      rel: 'alternate',
      hreflang: `${language}-CH`,
      href: global.locationOrigin + organization.preferredUri,
    },
    {
      rel: 'alternate',
      hreflang: language === DEFAULT_LANGUAGE ? 'de-CH' : 'fr-CH',
      href:
        language !== DEFAULT_LANGUAGE
          ? global.locationOrigin + organization.preferredUri.substring(3)
          : global.locationOrigin + '/fr' + organization.preferredUri,
    },
  ];

  return (
    <div className={`organization-detail ${styles.Wrapper}`}>
      <Helmet
        title={
          (organization.metaTitle && `${CATEGORY} ${organization.metaTitle}`) ||
          getFallbackTitle(organization)
        }
        link={metaLinksRestaurant}
      />
      <EditButtons
        editContentUri={organization?.editContentUri}
        editRelationUri={organization?.editRelationUri}
        cloneContentUri={organization?.cloneContentUri}
      />
      {(organization?.heroImageBody &&
        Array.isArray(organization?.heroImageBody) &&
        organization?.heroImageBody?.length > 0 && (
          <div className={styles.GoldenBorder}>
            <div className={styles.HeroWrapper}>
              <Hero heroImageBody={organization.heroImageBody} />
            </div>
            <div className={styles.GoldenBorderWrapper}>
              <div className={styles.GoldenBorder} />
            </div>
          </div>
        )) ||
        ''}
      {
        /* ranking */
        <div className={sections.Section}>
          <div className={sections.Container}>
            <div>
              <RestaurantRanking
                isProvisional={
                  organization?.organizationData?.isProvisional || false
                }
                points={
                  organization.organizationData.hasNoPoints
                    ? null
                    : organization.organizationData.points
                }
                pointsChange={organization.organizationData.pointsChange}
                trendIsDisabled={organization.organizationData.trendIsDisabled}
                origin={RESTAURANT_RANKING_ORIGIN_RESTAURANT}
              />
            </div>
          </div>
        </div>
      }

      {
        /* restaurant's name and address */
        <div className={sections.Section}>
          <div className={sections.Container}>
            <div>
              {organization.organizationData.isNew && (
                <div className={styles.NewLabel}>
                  <FormattedMessage
                    id="restaurant.description.new.label"
                    description="The default label for new restaurant"
                    defaultMessage="neu"
                  />
                </div>
              )}
              <div>
                <span className={styles.RestaurantLabel}>
                  <FormattedMessage
                    id="restaurant.description.title"
                    description="The default label for restaurant"
                    defaultMessage="Restaurant"
                  />
                </span>
              </div>
            </div>

            <div className={styles.RestaurantNameWrapper}>
              <h1 id={ANCHOR_TITLE} className={styles.RestaurantName}>
                {organization.organizationData.restaurantName || ''}
              </h1>
            </div>
            {organization && (
              <div className={styles.RestaurantAddress}>
                <div className={styles.ToggleBlock}>
                  {organization.organizationData.secondaryName &&
                    `${organization.organizationData.secondaryName}, `}
                </div>
                <div className={styles.ToggleBlock}>
                  {organization.address || ''}
                </div>
                <div>
                  {organization.zipCode &&
                    organization.city &&
                    `${organization.zipCode} ${organization.city}`}
                  {!organization.zipCode &&
                    organization.city &&
                    `${organization.city}`}
                </div>
              </div>
            )}
          </div>
        </div>
      }
      <div className={styles.UtilityBarWrapper}>
        <UtilityBar enabledUtilities={UTILITYBAR_CONFIG}>
          {({ isOverlayVisible, toggleOverlayVisible }) => (
            <UtilityOverlay
              overlayTitle={
                language === DEFAULT_LANGUAGE
                  ? UTILITY_BAR_SHARE_LABEL
                  : UTILITY_BAR_SHARE_LABEL_FR
              }
              isOverlayVisible={isOverlayVisible}
              toggleOverlayVisible={toggleOverlayVisible}
              enabledUtilities={UTILITYBAR_OVERLAY_CONFIG}
            />
          )}
        </UtilityBar>
      </div>
      {
        /* restaurant's contact and description */
        <div className={classNames(sections.Section, styles.TabSection)}>
          <div className={sections.Container}>
            <div className={grid.Row}>
              <div className={organizationColStyle}>
                <TabsTwoCols
                  addClass={grid.HiddenSmUp}
                  tabs={createTabsContent({
                    organization,
                    intl,
                    viewportLabel: VIEWPORT_XS,
                    language,
                    clientUrl,
                    isSSR,
                  })}
                />
                <TabsTwoCols
                  addClass={grid.HiddenSmDown}
                  tabs={createTabsContent({
                    organization,
                    intl,
                    language,
                    clientUrl,
                    isSSR,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      }

      <div className={sections.Section}>
        {organization.recommendations &&
          organization.recommendations.edges &&
          organization.recommendations.edges.length > 0 && (
            <RelatedContent
              relatedContent={organization.recommendations}
              teaserGridLayout={GRID_LAYOUT_TEASER_3X2}
              title={
                <FormattedMessage
                  id="app.article.relatedStories"
                  description="Heading above related stories teasers"
                  defaultMessage="Related Stories"
                />
              }
            />
          )}
      </div>
    </div>
  );
};

const createTabContact = ({
  organization,
  intl,
  language,
  clientUrl,
  isSSR,
}) => {
  if (!organization?.organizationData) {
    return null;
  }

  const { height: chiefCookImageHeight, width: chiefCookImageWidth } =
    getWidthAndHeightByImageStyle(STYLE_TEASER_1_1);

  const chiefCookImage = organization?.organizationData?.chiefCooks?.teaserImage
    ?.image
    ? assembleAkamaiImgUrl({
        relativeOriginPath:
          organization.organizationData.chiefCooks.teaserImage.image.file
            .relativeOriginPath,
        width: chiefCookImageWidth,
        height: chiefCookImageHeight,
        focalPointX:
          organization.organizationData.chiefCooks.teaserImage.image.file
            .focalPointY,
        focalPointY:
          organization.organizationData.chiefCooks.teaserImage.image.file
            .focalPointY,
        clientUrl,
      })
    : null;
  const availableServices = generateServices(organization, intl);
  const searchPath = language !== DEFAULT_LANGUAGE ? '/fr/search/' : '/suche/';
  const chefLastName =
    organization?.organizationData?.chiefCooks?.lastName || '';
  const chefSearch = searchPath + chefLastName.toLowerCase();
  const chefPreferredUri =
    organization?.organizationData?.chiefCooks?.preferredUri || '';
  const closedPeriod =
    (language === DEFAULT_LANGUAGE &&
      organization.organizationData.closedDe &&
      organization.organizationData.closedDe) ||
    (language !== DEFAULT_LANGUAGE &&
      organization.organizationData.closedFr &&
      organization.organizationData.closedFr);
  return (
    <div>
      {organization?.organizationData?.chiefCooks && chiefCookImage && (
        <div className={styles.ChiefCookPictureWrapper}>
          <img
            src={chiefCookImage}
            alt={organization.organizationData.chefName}
            className={styles.ChiefCookPicture}
          />
        </div>
      )}
      {
        /* chef */
        <div className={styles.ParagraphLeftTab}>
          <span className={styles.Bold}>
            <FormattedMessage
              id="restaurant.body.chef"
              description="The default label for chef"
              defaultMessage="Chef"
            />
            :{' '}
          </span>
          {organization?.organizationData?.chefName &&
            (chefLastName || chefPreferredUri ? (
              <Link
                className={styles.Link}
                path={chefSearch || chefPreferredUri}
                label={organization.organizationData.chefName}
              />
            ) : (
              organization.organizationData.chefName
            ))}
        </div>
      }
      {
        /* Owners */
        organization?.organizationData?.owners?.name && (
          <div className={styles.ParagraphLeftTab}>
            <span className={styles.Bold}>
              <FormattedMessage
                id="restaurant.body.owner"
                description="The default label for owner"
                defaultMessage="Gastgeber"
              />
              :{' '}
            </span>
            {organization.organizationData.owners.name}
          </div>
        )
      }

      {
        /* holiday */
        closedPeriod && (
          <div className={styles.ParagraphLeftTab}>
            <span className={styles.Bold}>
              <FormattedMessage
                id="restaurant.body.holiday"
                description="The default label for holiday"
                defaultMessage="Ruhetage"
              />
              :&nbsp;
            </span>
            {closedPeriod}
          </div>
        )
      }

      {
        /* prices */
        ((organization.organizationData.lunchPriceLow ||
          organization.organizationData.lunchPriceHigh ||
          organization.organizationData.dinerPriceLow ||
          organization.organizationData.dinerPriceHigh) && (
          <div className={styles.ParagraphLeftTab}>
            <span className={styles.Bold}>
              <FormattedMessage
                id="restaurant.body.price"
                description="The default label for price"
                defaultMessage="Preise"
              />
              {': '}
            </span>

            {
              /* prices lunch */
              ((organization.organizationData.lunchPriceLow ||
                organization.organizationData.lunchPriceHigh) && (
                <span className={styles.NoWrap}>
                  <FormattedMessage
                    id="restaurant.body.menu.lunch"
                    description="The default label for lunch"
                    defaultMessage="M"
                  />{' '}
                  {(organization.organizationData.lunchPriceLow &&
                    formatPrice(organization.organizationData.lunchPriceLow)) ||
                    ''}
                  {(organization.organizationData.lunchPriceLow &&
                    organization.organizationData.lunchPriceHigh &&
                    '/') ||
                    ''}
                  {(organization.organizationData.lunchPriceHigh &&
                    formatPrice(
                      organization.organizationData.lunchPriceHigh,
                    )) ||
                    ''}
                </span>
              )) ||
                ''
            }

            {
              /* prices divider */
              ((organization.organizationData.lunchPriceLow ||
                organization.organizationData.lunchPriceHigh) &&
                (organization.organizationData.dinerPriceLow ||
                  organization.organizationData.dinerPriceHigh) &&
                ' • ') ||
                ''
            }

            {
              /* prices diner */
              ((organization.organizationData.dinerPriceLow ||
                organization.organizationData.dinerPriceHigh) && (
                <span className={styles.NoWrap}>
                  <FormattedMessage
                    id="restaurant.body.menu.diner"
                    description="The default label for diner"
                    defaultMessage="D"
                  />{' '}
                  {(organization.organizationData.dinerPriceLow &&
                    formatPrice(organization.organizationData.dinerPriceLow)) ||
                    ''}
                  {organization.organizationData.dinerPriceLow && '/'}
                  {(organization.organizationData.dinerPriceHigh &&
                    formatPrice(
                      organization.organizationData.dinerPriceHigh,
                    )) ||
                    ''}
                </span>
              )) ||
                ''
            }
          </div>
        )) ||
          ''
      }
      {
        /* services */
        availableServices && (
          <div className={styles.ParagraphLeftTab}>
            <span className={styles.Bold}>
              <FormattedMessage
                id="restaurant.body.service"
                description="The default label for service"
                defaultMessage="Service"
              />
              :{' '}
            </span>
            <span>{availableServices}</span>
          </div>
        )
      }
      {
        /* telephone */
        organization.phone && (
          <div className={styles.ParagraphLeftTab}>
            <span className={styles.Bold}>
              <FormattedMessage
                id="restaurant.body.telephone"
                description="The default label for telephone"
                defaultMessage="Telefon"
              />
              :{' '}
            </span>
            <Link
              path={`tel:${organization.phone}`}
              label={organization.phone}
              className={styles.Link}
            />
          </div>
        )
      }
      {
        /* e-mail */
        organization.email && (
          <div className={styles.ParagraphLeftTab}>
            <span className={styles.Bold}>
              <FormattedMessage
                id="restaurant.body.email"
                description="The default label for email"
                defaultMessage="E-Mail"
              />
              :{' '}
            </span>
            {!isSSR && (
              <Link
                onClick={() =>
                  (window.location.href = `mailto:${organization.email}`)
                }
                label={organization.email}
                className={styles.Link}
              />
            )}
          </div>
        )
      }
      {
        /* web site link */
        organization.website && (
          <div className={styles.ParagraphLeftTab}>
            <Link
              path={getWebSite(organization.website)}
              className={styles.Link}
              target="_blank"
            >
              <FormattedMessage
                id="restaurant.body.website"
                description="The default link label to restaurant web site"
                defaultMessage="Zur Restaurant-Website"
              />
            </Link>
          </div>
        )
      }
      <OrganizationShare {...organization} />
      {organization?.sponsors?.items && (
        <div className={styles.ParagraphLeftTabLast}>
          {organization.sponsors.items.map((sponsor) => (
            <Link
              path={sponsor.teaserImage?.link?.path}
              className={styles.Link}
              key={`sponsor-${sponsor.id}`}
              target="_blank"
            >
              <SponsorImage sponsor={sponsor} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const createTabsContent = ({
  organization,
  intl,
  language,
  clientUrl,
  viewportLabel = '',
  isSSR,
}) => {
  const tabs = [
    {
      index: 2,
      anchor: 'contact',
      title: (
        <FormattedMessage
          id="restaurant.body.tab.contact"
          description="The default label for contact tab"
          defaultMessage="Kontakt"
        />
      ),
      subtitle: '',
      contents: [
        {
          content: createTabContact({
            organization,
            intl,
            language,
            clientUrl,
            isSSR,
          }),
          addClass: '',
        },
      ],
      addClass: '',
    },
    {
      index: 1,
      anchor: 'text',
      title: (
        <FormattedMessage
          id="restaurant.body.tab.text"
          description="The default label for text tab"
          defaultMessage="Text"
        />
      ),
      subtitle: '',
      contents: [
        {
          content:
            language !== DEFAULT_LANGUAGE &&
            organization.organizationData.reviewFr
              ? organization.organizationData.reviewFr
              : organization.organizationData.reviewDe || '',
          addClass: styles.OrganizationText,
        },
      ],
      addClass: '',
    },
  ];

  // revert order for mobile
  if (viewportLabel === VIEWPORT_XS) {
    return tabs.reverse();
  }

  return tabs;
};

const mapDispatchToProps = {
  setHeaderData,
  resetHeaderData,
};

const mapStateToProps = (state) => ({
  clientUrl: locationStateSelector(state).clientUrl,
});

export default compose(
  withHeaderProps,
  connect(mapStateToProps, mapDispatchToProps),
  withHelmet({
    getNode: (mapProps) => mapProps.organization,
    rootSchemaType: ROOT_SCHEMA_TYPE_NEWS_ARTICLE,
    hasBreadcrumbs: () => false,
  }),
)(OrganizationRestaurant);
