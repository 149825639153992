import React from 'react';
import { SocialIconProps } from '../../typings';

const PinterestIcon = ({
  className,
  classNameGradient,
  id,
}: Omit<SocialIconProps, 'type'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    className={className}
    id={id}
  >
    <g clipPath="url(#clip0_1_2)" className={classNameGradient}>
      <mask
        id="mask0_1_2"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="22"
      >
        <path d="M22 0H0V22H22V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1_2)">
        <path
          d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
          fill="black"
        />
        <path
          d="M11.8829 4.39999C8.00519 4.39999 6.05 7.1979 6.05 9.53122C6.05 10.9439 6.58141 12.2006 7.72106 12.6691C7.90817 12.7459 8.07576 12.6718 8.13015 12.4636C8.16766 12.3194 8.25687 11.9559 8.29658 11.8044C8.35125 11.5983 8.32991 11.5262 8.17916 11.3467C7.85053 10.9565 7.64049 10.4514 7.64049 9.73591C7.64049 7.66012 9.18372 5.80189 11.6591 5.80189C13.8506 5.80189 15.0548 7.14972 15.0548 8.94965C15.0548 11.318 14.0134 13.3168 12.4673 13.3168C11.6134 13.3168 10.9745 12.6061 11.1794 11.7345C11.4246 10.694 11.8996 9.57091 11.8996 8.81996C11.8996 8.14764 11.5413 7.58675 10.7991 7.58675C9.92622 7.58675 9.22515 8.49551 9.22515 9.71281C9.22515 10.4881 9.48539 11.0126 9.48539 11.0126C9.48539 11.0126 8.59204 14.8214 8.43579 15.4885C8.12377 16.8167 8.38898 18.4452 8.41137 18.6096C8.42429 18.7071 8.5487 18.7302 8.60513 18.6567C8.68587 18.5509 9.72528 17.2595 10.0787 15.9688C10.1786 15.6034 10.6524 13.7111 10.6524 13.7111C10.9361 14.2554 11.7649 14.7349 12.6463 14.7349C15.2698 14.7349 17.05 12.3278 17.05 9.10546C17.0502 6.66902 14.9994 4.39999 11.8829 4.39999Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default PinterestIcon;
