import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withHandlers from 'recompose/withHandlers';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import handleWysiwygLink from '../../../shared/helpers/handleWysiwygLink';
import withNavigate, {
  WithNavigateProps,
} from '../../../shared/decorators/withNavigate';
import {
  VIEWPORT_XS,
  getCurrentViewport,
} from '../../../shared/actions/window';
import { AppSetupFactoryOptions } from './typings';

type AppSetupPropsInner = AppSetupFactoryOptions &
  WithNavigateProps & {
    dispatchWindowResizeEvent: (props: AppSetupPropsInner) => void;
    dispatchWindowScrollEvent: (props: AppSetupPropsInner) => void;
  };

const sendViewportMessage = () => {
  const viewportSize = getCurrentViewport(window.innerWidth)?.label;
  const nodes = document.querySelectorAll(`div[class*="piano-template-"]`);
  Array.from(nodes).forEach((node: Record<string, any>): void => {
    const iframe = node.getElementsByTagName('iframe')[0];
    if (!iframe) {
      return;
    }
    if (viewportSize === VIEWPORT_XS) {
      iframe.contentWindow.postMessage(
        'mobile',
        __PIANO_ENDPOINT__?.replace('/api/v3', ''),
      );
    } else {
      iframe.contentWindow.postMessage(
        'desktop',
        __PIANO_ENDPOINT__?.replace('/api/v3', ''),
      );
    }
  });
};

export default ({
  setScrollTop,
  windowResize,
  windowResizeDebounceValue,
  isWindowStateDefinedOnClient = false,
}: AppSetupFactoryOptions) => {
  const getDebouncedWindowResizeFn: (props: AppSetupPropsInner) => any = (
    props: AppSetupPropsInner,
  ) => debounce(props.dispatchWindowResizeEvent, windowResizeDebounceValue);

  const performInitialDispatches = (props: AppSetupPropsInner) => {
    if (__CLIENT__) {
      // just dispatch this on client, because server side detects window size based on varnish headers
      props.windowResize(window);
    }
  };

  let supportsPassive: boolean;
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
      },
    });
    window.addEventListener('test', null, opts);
  } catch (e) {
    supportsPassive = false;
  }

  const getScrollThrottleFn = (props: AppSetupPropsInner) =>
    throttle(props.dispatchWindowScrollEvent, 16);

  const bindListeners = (props: AppSetupPropsInner) => {
    window.addEventListener('resize', getDebouncedWindowResizeFn(props));
    window.addEventListener(
      'scroll',
      getScrollThrottleFn(props),
      supportsPassive ? { passive: true } : false,
    );

    window.onbeforeprint = () => {
      const images: NodeListOf<HTMLImageElement> = document.querySelectorAll(
        'img[loading="lazy"]',
      );

      images.forEach((image: any) => {
        image.loading = 'eager';
      });
    };
    window.addEventListener('message', (event) => {
      if (
        event.origin === __PIANO_ENDPOINT__?.replace('/api/v3', '') &&
        event.data === 'piano-template-loaded'
      ) {
        sendViewportMessage();
      }
    });
  };

  const unbindListeners = (props: AppSetupPropsInner) => {
    window.removeEventListener('resize', getDebouncedWindowResizeFn(props));
    window.removeEventListener('scroll', getScrollThrottleFn(props));
    window.removeEventListener('message', (event) => {
      if (
        event.origin === __PIANO_ENDPOINT__?.replace('/api/v3', '') &&
        event.data === 'piano-template-loaded'
      ) {
        sendViewportMessage();
      }
    });
  };

  const mapDispatchToProps = {
    setScrollTop,
    windowResize,
  };

  const withStoreConnection = connect(undefined, mapDispatchToProps);

  const withExtendedHandlers = withHandlers({
    dispatchWindowResizeEvent: (props: AppSetupPropsInner) => () => {
      if (__CLIENT__) {
        sendViewportMessage();
        return props.windowResize(window);
      }

      return;
    },
    dispatchWindowScrollEvent: (props: AppSetupPropsInner) => () =>
      props.setScrollTop(),
  });

  const withLifecycle = lifecycle({
    componentDidMount() {
      window.handleWysiwygLink = (event) =>
        handleWysiwygLink(event, this.props.navigate);
      window.dispatchEvent(new Event('handleWysiwygLink-initialized'));
      bindListeners(this.props);

      // just dispatch the resize event on client, if we hydrate
      // the window state from ssr (is not the case after x-device removal)
      if (!isWindowStateDefinedOnClient) {
        performInitialDispatches(this.props);
      }
    },
    componentWillUnmount() {
      unbindListeners(this.props);
    },
  });

  const AppSetup = () => null;

  return compose(
    withNavigate,
    withStoreConnection,
    withExtendedHandlers,
    withLifecycle,
  )(AppSetup);
};
