export const EMBED_CONSENT_BLOCK_CONTENT = {
  title: 'Externe Inhalte',
  buttonLabel: 'Cookies zulassen',
  leadContent:
    'An dieser Stelle findest du einen ergänzenden externen Inhalt. Falls du damit einverstanden bist, dass Cookies von externen Anbietern gesetzt und dadurch personenbezogene Daten an externe Anbieter übermittelt werden, kannst du alle Cookies zulassen und externe Inhalte direkt anzeigen.',
  linkLabel: 'mehr Infos',
  fr: {
    title: 'Contenu externe',
    buttonLabel: 'Autoriser les cookies',
    leadContent:
      'Vous trouverez ici un contenu externe complémentaire. Si vous acceptez que des cookies soient installés par des fournisseurs externes et que des données personnelles soient ainsi transmises à ces derniers, vous pouvez autoriser tous les cookies et afficher directement le contenu externe.',
    linkLabel: "plus d'infos",
  },
};
