import React from 'react';
import { compose, withHandlers } from 'recompose';
import classNames from 'classnames';
import ShareLink from '../../../../components/ShareLink';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { ShareItemProps, SharePanelProps } from './typings';

type SharePanelPropsInner = SharePanelProps & {
  convertUrl: (props: ShareItemProps) => string;
};

const ICON_TYPE_PHONE = 'IconPhone';
const ICON_TYPE_LOCATION = 'IconLocation';
const QUESTION_MARK = '%3F';
const AMPERSAND = '%26';

const shareItems = [
  {
    iconType: ICON_TYPE_LOCATION,
    iconLabel: 'Route',
    url: 'https://www.google.ch/maps/dir//[address]',
  },
  {
    iconType: ICON_TYPE_PHONE,
    iconLabel: 'Anrufen',
    url: 'tel:[telephone]',
    addClass: grid.HiddenSmUp,
  },
];

const ShareItems = ({ convertUrl }: SharePanelPropsInner) =>
  shareItems.map((shareItem) => {
    const parsedUrl = convertUrl(shareItem);
    return (
      parsedUrl && (
        <ShareLink
          iconType={shareItem.iconType}
          key={`organization-share-item-${shareItem.iconLabel}`}
          url={parsedUrl}
          addClass={classNames(styles.SharePanelItem, {
            [shareItem.addClass]: !!shareItem.addClass,
          })}
          iconAddClass={styles.Icon}
          ariaLabel={`Link zu ${shareItem.iconLabel}`}
        >
          <span className={styles.Label}>{shareItem.iconLabel}</span>
        </ShareLink>
      )
    );
  });

const doConvertUrl = (url: string, additionalQueryParam = '', props: any) => {
  const shareUrl: string = global.locationOrigin + props.routePathname;
  const hasQueryParam =
    shareUrl.indexOf('?') !== -1 || shareUrl.indexOf(QUESTION_MARK) !== -1;
  const paramDelimiter =
    hasQueryParam && additionalQueryParam ? AMPERSAND : QUESTION_MARK;
  const finalUrl =
    (additionalQueryParam &&
      `${shareUrl}${paramDelimiter}${additionalQueryParam}`) ||
    shareUrl;

  const parsed = url
    .replace(/\[url\]/gi, finalUrl)
    .replace(/\[field_heroimage\]/gi, encodeURIComponent(props.heroImage))
    .replace(/\[field_short_title\]/gi, encodeURIComponent(props.shareTitle))
    .replace(/\[field_lead\]/gi, encodeURIComponent(props.lead))
    .replace(
      /\[address\]/gi,
      props.address &&
        encodeURIComponent(
          `${props.title}, ${props.address}, ${props.zipCode} ${props.city}`,
        ),
    )
    .replace(/\[telephone\]/gi, encodeURIComponent(props.phone));

  return parsed.indexOf('null') !== -1 ? null : parsed;
};

const withConvertUrlHandler = withHandlers({
  convertUrl: (props: SharePanelPropsInner) => (link: ShareItemProps) =>
    doConvertUrl(link.url, link.referrer, props),
});

export default compose(withConvertUrlHandler)(ShareItems);
