/**
 * @file   with page pager
 * @author Roman Zanettin <roman.zanettin@ringieraxelspringer.ch>
 * @date   2017-04-01
 *
 */

/* istanbul ignore file */

import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import namedComponent from './namedComponent';
import withMapQueryStringToProps from './withMapQueryStringToProps';
import withNavigate from './withNavigate';
import withParams from './withParams';

// ---------------------------------------------------------------------------------- //
// COMPOSE
// ---------------------------------------------------------------------------------- //

const doUpdatePage = (props, newPage) => {
  const query = {
    ...props.location.query,
    page: newPage,
  };

  // remove page query string if page is 1
  if (newPage < 2) {
    delete query.page;
  }

  props.navigate({
    pathname: props.location.pathname,
    query,
  });
};

const withPagerHandlers = withHandlers({
  updatePage: (props) => (newPage) => {
    doUpdatePage(props, newPage);
  },
});

export default (Component) =>
  compose(
    namedComponent('withPagePager'),
    withNavigate,
    withParams,
    withMapQueryStringToProps({
      name: 'page',
      initialValue: 1,
      parseInt: true,
    }),
    withPagerHandlers,
  )(Component);
