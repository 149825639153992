/**
 * @file   global search graphql constants
 */

import {
  ARTICLE_CONTENT_TYPE,
  DOSSIER_CONTENT_TYPE,
  EXPLAINING_ARTICLE_CONTENT_TYPE,
  IMAGE_GALLERY_CONTENT_TYPE,
  KEYWORD_SETTINGS_CONTENT_TYPE,
  LANDING_PAGE_CONTENT_TYPE,
  NATIVE_ADVERTISING_CONTENT_TYPE,
  ORGANIZATION_CONTENT_TYPE,
  PAGE_CONTENT_TYPE,
  PERSON_CONTENT_TYPE,
  PRODUCT_CONTENT_TYPE,
  RECIPE_CONTENT_TYPE,
  SPONSOR_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from './content';

export const GLOBAL_SEARCH_FILTER_ARTICLE: string = ARTICLE_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_DOSSIER: string = DOSSIER_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_EXPLAINING_ARTICLE: string =
  EXPLAINING_ARTICLE_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_IMAGE_GALLERY: string =
  IMAGE_GALLERY_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_KEYWORD_SETTINGS: string =
  KEYWORD_SETTINGS_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_LANDING_PAGE: string =
  LANDING_PAGE_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING: string =
  NATIVE_ADVERTISING_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_ORGANIZATION: string =
  ORGANIZATION_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_PAGE: string = PAGE_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_PERSON: string = PERSON_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_PRODUCT: string = PRODUCT_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_RECIPE: string = RECIPE_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_SPONSOR: string = SPONSOR_CONTENT_TYPE;
export const GLOBAL_SEARCH_FILTER_VIDEO: string = VIDEO_CONTENT_TYPE;

export const GLOBAL_SEARCH_SORT_BY_RELEVANCE = 'Relevance';
export const GLOBAL_SEARCH_SORT_BY_MODIFICATION_DATE = 'ModificationDate';
export const GLOBAL_SEARCH_SORT_BY_DATE = 'Date';
export const GLOBAL_SEARCH_SORT_BY_CHANGED = 'Changed';
export const GLOBAL_SEARCH_SORT_BY_STICKY = 'Sticky';
export const GLOBAL_SEARCH_SORT_BY_POINTS = 'Points';
export const GLOBAL_SEARCH_SORT_BY_UPDATED_AT = 'UpdatedAt';
export const GLOBAL_SEARCH_SORT_BY_PUBLICATION_DATE = 'PublicationDate';
export const GLOBAL_SEARCH_SORT_BY_PUBLICATION_DATE_WITH_STICKY =
  'PublicationDateWithSticky';

export const GLOBAL_SEARCH_SORT_ASC = 'Ascending';
export const GLOBAL_SEARCH_SORT_DESC = 'Descending';
