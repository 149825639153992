/* istanbul ignore file */

import React from 'react';
import teaserGridFactory from '../../../../../common/components/TeaserGrid/factory';
import AppNexus from '../AppNexus';
import Error from '../Error';
import HomeSlider from '../HomeSlider';
import Teaser from '../Teaser';
import {
  TYPE_AD,
  TYPE_STATIC,
} from '../../../../../common/components/TeaserGrid/gridConfigs/constants';
import { TYPE_DIVIDER, TYPE_HEROSLIDER_HOME } from './gridConfigs/constants';
import styles from './styles.legacy.css';
import { TeaserLayout, gridConfig } from './gridConfigs';
import { EnrichedGridConfig } from '../../../../../common/components/TeaserGrid/typings';

const getGridItem = (item: EnrichedGridConfig) => {
  switch (item.type) {
    case TYPE_HEROSLIDER_HOME: {
      return <HomeSlider teaserList={item.items} />;
    }
    case TYPE_DIVIDER: {
      return <div className={styles.Divider} />;
    }
    case TYPE_STATIC: {
      return <Teaser component={item.teaserType} />;
    }
    case TYPE_AD:
      return (
        <>
          {item.adConfig &&
            Array.isArray(item.adConfig) &&
            item.adConfig.map(
              ({ slot, isMultiPlacement = true, deviceType }, index) => (
                <div
                  className={`ad-wrapper ad-wrapper-${deviceType}`}
                  key={`teaser-grid-ad-${index}`}
                >
                  <AppNexus
                    slot={slot}
                    isMultiPlacement={isMultiPlacement}
                    deviceType={deviceType}
                  />
                </div>
              ),
            )}
        </>
      );
    default:
      return null;
  }
};

const TeaserGrid = teaserGridFactory<Record<TeaserLayout, any>>({
  ErrorMessage: Error,
  Teaser,
  cssGridConfig: gridConfig,
  getGridItem,
});

export default TeaserGrid;
