import {
  IAV,
  MHPA,
  MMR,
  MPA,
  MR,
  MWB,
  PREROLL,
  SBA,
  WB,
} from '../../../../../shared/constants/ads';

// desktop
export const WB_1 = `${WB}1`;
export const SBA_1 = `${SBA}1`;
export const MR_1 = `${MR}1`;
export const IAV_1 = `${IAV}1`;
export const WIDEBOARD_2 = `${WB}2-${MR}`; // WB_2-MR
export const WIDEBOARD_3 = `${WB}3-${MR}`; // WB_3-MR

// mobile
export const MWB_1 = `${MWB}1`;
export const MMR_1 = `${MMR}1`;
export const MHPA_2 = `${MHPA}2`;
export const MPA_3 = `${MPA}3`;
export const MPA_4 = `${MPA}4`;

// desktop & mobile
export const PREROLL_1 = `${PREROLL}1`;
