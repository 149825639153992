// Tealium tracking classes
export const TRACKING_CLASS_CONTENT_HEADER = 'content-header';
export const TRACKING_CLASS_CONTENT_HERO = 'content-hero';
export const TRACKING_CLASS_CONTENT_BODY = 'content-body';
export const TRACKING_CLASS_CONTENT_KEYWORDS = 'content-keywords';
export const TRACKING_CLASS_CONTENT_RECOMMENDATIONS = 'content-recommendations';

export const TRACKING_CLASS_SITE_HEADER = 'site-header';
export const TRACKING_CLASS_SITE_FOOTER = 'site-footer';

export const TRACKING_CLASS_ARTICLE_HEADER = 'article-header';
export const TRACKING_CLASS_ARTICLE_BODY = 'article-body';
export const TRACKING_CLASS_ARTICLE_HERO = 'article-hero';
export const TRACKING_CLASS_ARTICLE_KEYWORDS = 'article-keywords';
export const TRACKING_CLASS_ARTICLE_RECOMMENDATIONS = 'article-recommendations';

export const TRACKING_CLASS_PARAGRAPH = 'paragraph';
export const TRACKING_CLASS_EMBED_PARAGRAPH = 'embed-paragraph';
export const TRACKING_CLASS_PLACEHOLDER_PARAGRAPH = 'placeholder-paragraph';
export const TRACKING_CLASS_BLOCKQUOTE_PARAGRAPH = 'blockquote-paragraph';
export const TRACKING_CLASS_WEBFORM_PARAGRAPH = 'webform-paragraph';
export const TRACKING_CLASS_INPUTFORM_PARAGRAPH = 'inputform-paragraph';

export const TRACKING_CLASS_MINISTAGE_VIDEO_PARAGRAPH =
  'ministage-video-paragraph';
export const TRACKING_CLASS_MINISTAGE_TRENDING_TOPICS_PARAGRAPH =
  'ministage-trending-topics-paragraph';
export const TRACKING_CLASS_MINISTAGE_TEASER_PARAGRAPH =
  'ministage-teaser-paragraph';
export const TRACKING_CLASS_MINISTAGE_NEWSLETTER_PARAGRAPH =
  'ministage-newsletter-paragraph';
export const TRACKING_CLASS_MINISTAGE_CHANNEL_SPONSOR_PARAGRAPH =
  'ministage-channel-sponsor-paragraph';
export const TRACKING_CLASS_MINISTAGE_GUIDER_PARAGRAPH =
  'ministage-guider-paragraph';
export const TRACKING_CLASS_MINISTAGE_LISTICLE_PARAGRAPH =
  'ministage-listicle-paragraph';
export const TRACKING_CLASS_MINISTAGE_LISTICLE_ITEM_PARAGRAPH =
  'ministage-listicle-item-paragraph';
export const TRACKING_CLASS_MINISTAGE_SOCIAL_MEDIA_PARAGRAPH =
  'ministage-social-media-paragraph';
export const TRACKING_CLASS_MINISTAGE_DISRUPTOR_PARAGRAPH =
  'ministage-disruptor-paragraph';
export const TRACKING_CLASS_MINISTAGE_SINGLE_ALERT_TOPIC =
  'ministage-single-alert-topic';
export const TRACKING_CLASS_MINISTAGE_SPONSOR_LOGO_BOX =
  'ministage-sponsor-logo-box';

export const TRACKING_CLASS_CONTENT_STAGE_PARAGRAPH = 'content-stage-paragraph';
export const TRACKING_CLASS_TEASER_STAGE_PARAGRAPH = 'teaser-stage-paragraph';
export const TRACKING_CLASS_ENTITY_QUEUE_PARAGRAPH = 'entity-queue-paragraph';
export const TRACKING_CLASS_HOROSCOPE_STAGE_PARAGRAPH =
  'horoscope-stage-paragraph';
export const TRACKING_CLASS_IMAGE_GALLERY_PARAGRAPH = 'image-gallery-paragraph';
export const TRACKING_CLASS_IMAGE_GALLERY_HERO_PARAGRAPH =
  'image-gallery-hero-paragraph';
export const TRACKING_CLASS_IMAGE_HERO_PARAGRAPH = 'image-hero-paragraph';
export const TRACKING_CLASS_IMAGE_GALLERY_DETAIL_PARAGRAPH =
  'image-gallery-detail-paragraph';
export const TRACKING_CLASS_VIDEO_HERO_PARAGRAPH = 'video-hero-paragraph';
export const TRACKING_CLASS_VIDEO_LOOP_PARAGRAPH = 'video-loop-paragraph';
export const TRACKING_CLASS_TEASER_PARAGRAPH = 'teaser-paragraph';
export const TRACKING_CLASS_BOOK_TEASER_PARAGRAPH = 'book-teaser-paragraph';
export const TRACKING_CLASS_INLINE_TEASER_PARAGRAPH = 'inline-teaser-paragraph';
export const TRACKING_CLASS_MULTI_COLUMN_PARAGRAPH = 'multi-column-paragraph';
export const TRACKING_CLASS_NATIVE_ADVERTISING_PARAGRAPH =
  'native-advertising-paragraph';
export const TRACKING_CLASS_INFO_BOX_PARAGRAPH = 'info-box-paragraph';
export const TRACKING_CLASS_LINK_BOX_PARAGRAPH = 'link-box-paragraph';
export const TRACKING_CLASS_DEFAULT_IMAGE_PARAGRAPH = 'default-image-paragraph';
export const TRACKING_CLASS_PARALLAX_IMAGE_PARAGRAPH =
  'parallax-image-paragraph';
export const TRACKING_CLASS_IMAGE_PARAGRAPH = 'image-paragraph';
export const TRACKING_CLASS_VIDEO_PARAGRAPH = 'video-paragraph';
export const TRACKING_CLASS_TEXT_PARAGRAPH = 'text-paragraph';
export const TRACKING_CLASS_FOCUS_BOX_PARAGRAPH = 'focus-box-paragraph';
export const TRACKING_CLASS_RANKING_LIST_PARAGRAPH = 'ranking-list-paragraph';
export const TRACKING_CLASS_LISTICLE_ITEM_PARAGRAPH = 'listicle-paragraph';

export const TRACKING_CLASS_TEASER = 'teaser';
export const TRACKING_TEASER_POSITION = 'teaser-position';
export const TRACKING_TEASER_PARAGRAPH_TYPE = 'paragraph-type';
export const TRACKING_TEASER_PARAGRAPH_INDEX = 'paragraph-index';
export const TRACKING_TEASER_ROW_INDEX = 'grid-row-index';
export const TRACKING_CLASS_PRINT_TEASER = 'teaser_print';

export const TRACKING_PARAMS_SHOP =
  '?promo_name=heft-teaser&promo_position=print-article';

export const TRACKING_SLIDER_DIRECTION_BACKWARD = 'direction/backwards';
export const TRACKING_SLIDER_DIRECTION_FORWARD = 'direction/forwards';
