import React, { Component, MouseEvent, ReactElement } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { scrollToAnchorElement } from './helpers';
import { ANCHOR_TAG_SCROLL_TO_TOP } from './constants';
import { SmoothScrollProps } from './typings';

const SmoothScrollFactory = () => {
  class SmoothScroll extends Component<SmoothScrollProps> {
    static defaultProps = {
      offset: 60,
      behavior: 'smooth',
    };

    constructor(props: SmoothScrollProps) {
      super(props);
    }

    scrollToAnchorElement = (event: MouseEvent) => {
      event.preventDefault();
      scrollToAnchorElement(this.props.anchorId, {
        behavior: this.props.behavior,
        offset: this.props.offset,
        scrollToTopAnchorId: ANCHOR_TAG_SCROLL_TO_TOP,
        replace: true,
      });
    };

    registerListener() {
      if (this.props.anchorId) {
        setTimeout(() => {
          smoothscroll.polyfill();

          Array.from(
            document.querySelectorAll(
              `a:not([href^="http"])[href*="#${encodeURI(
                this.props.anchorId,
              )}"]`,
            ),
          ).forEach((linkAnchor: Record<string, any>): void => {
            if (global.location.hash === `#${encodeURI(this.props.anchorId)}`) {
              scrollToAnchorElement(this.props.anchorId, {
                behavior: this.props.behavior,
                offset: this.props.offset,
                scrollToTopAnchorId: ANCHOR_TAG_SCROLL_TO_TOP,
              });
            }
            linkAnchor.addEventListener('click', this.scrollToAnchorElement);
          });
        }, 100);
      }
    }

    unregisterListener() {
      if (this.props.anchorId) {
        Array.from(
          document.querySelectorAll(
            `a:not([href^="http"])[href*="#${encodeURI(this.props.anchorId)}"]`,
          ),
        ).forEach((linkAnchor: Record<string, any>): void => {
          linkAnchor.removeEventListener('click', this.scrollToAnchorElement);
        });
      }
    }

    componentDidMount() {
      this.registerListener();
    }

    componentDidUpdate() {
      this.unregisterListener();
      this.registerListener();
    }

    componentWillUnmount = () => {
      this.unregisterListener();
    };

    render(): ReactElement | null {
      const { anchorId, children } = this.props;

      if (!anchorId) {
        return <>{children || null}</>;
      }

      return (
        <div
          key={`smooth-scroll-${anchorId}`}
          data-testid="smooth-scroll-factory-wrapper"
          id={anchorId}
        >
          {children}
        </div>
      );
    }
  }

  return SmoothScroll;
};

export default SmoothScrollFactory;
