import { connect } from 'react-redux';
import shouldUpdate from 'recompose/shouldUpdate';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import debounce from 'lodash.debounce';
import { doHandleSearchSuggestionsClickTracking } from '../../../../../../../../shared/helpers/tracking';
import { searchToggle as searchToggleAction } from '../../../../../../../../shared/actions/search';
import { URL_DE_SEARCH } from '../../../../../constants';

export const MIN_QUERY_LENGTH = 3;
export const AUTOCOMPLETE_ITEMS = 5;

const AUTOCOMPLETE_DEBOUNCE_TIME = 300;

const doSubmit = (
  event,
  searchQuery,
  setIsAutocompleteVisible,
  onSubmitRoute = URL_DE_SEARCH,
  searchToggle,
  navigate,
) => {
  if (event) {
    event.preventDefault();
  }

  searchToggle(false);

  if (searchQuery && searchQuery.length >= MIN_QUERY_LENGTH) {
    // close autocomplete box
    setIsAutocompleteVisible(false);

    // navigate to search page
    setTimeout(() => {
      // wait 1 tick, so the system has time to react to the "searchToggle(false)" before we change to next page
      const encodedQuery = encodeURIComponent(searchQuery.toLowerCase());
      navigate(`${onSubmitRoute}/${encodedQuery}`);
    }, 0);
  }
};

const getAutocompleteItemsCount = (props) => {
  const itemsCount =
    (props.data &&
      props.data.globalSearch &&
      props.data.globalSearch.edges &&
      props.data.globalSearch.edges.length) ||
    0;
  return itemsCount;
};

const decreaseAutocompleteSelectedIndex = (props) => {
  const index = props.autocompleteSelectedIndex;
  const itemsCount = getAutocompleteItemsCount(props);
  if (index > -1) {
    props.setAutocompleteSelectedIndex(index - 1);
  } else {
    props.setAutocompleteSelectedIndex(itemsCount - 1);
  }
};

const increaseAutocompleteSelectedIndex = (props) => {
  const index = props.autocompleteSelectedIndex;
  const itemsCount = getAutocompleteItemsCount(props);
  if (index + 1 < itemsCount) {
    props.setAutocompleteSelectedIndex(index + 1);
  } else {
    props.setAutocompleteSelectedIndex(-1);
  }
};

const navigateToSelectedItem = (props) => {
  const uri =
    (props.data &&
      props.data.globalSearch &&
      props.data.globalSearch.edges &&
      Array.isArray(props.data.globalSearch.edges) &&
      props.data.globalSearch.edges[props.autocompleteSelectedIndex] &&
      props.data.globalSearch.edges[props.autocompleteSelectedIndex].node &&
      props.data.globalSearch.edges[props.autocompleteSelectedIndex].node
        .preferredUri) ||
    null;

  // nothing selected or invalid data received => submit the form
  if (!uri) {
    doSubmit(
      null,
      props.searchQuery,
      props.setIsAutocompleteVisible,
      props.onSubmitRoute,
      props.searchToggle,
      props.navigate,
    );
    return;
  }

  props.onBeforeNavigate();
  props.navigate(uri);
};

export const keyBindingConfig = {
  keyMap: {
    arrowDown: {
      action: (props) => increaseAutocompleteSelectedIndex(props),
      test: (props) => props.isAutocompleteVisible,
    },
    arrowUp: {
      action: (props) => decreaseAutocompleteSelectedIndex(props),
      test: (props) => props.isAutocompleteVisible,
    },
    enter: {
      action: (props) => navigateToSelectedItem(props),
      test: (props) => props.isAutocompleteVisible,
    },
    escape: {
      action: (props) => {
        props.setAutocompleteSelectedIndex(-1);
        props.setIsAutocompleteVisible(false);
        props.searchToggle(false);
      },
    },
  },
};

// ---------------------------------------------------------------------------------- //
// LOGIC
// ---------------------------------------------------------------------------------- //

export const withExtendedSubmitHandler = withHandlers({
  handleSubmit: (props) => (event) =>
    doSubmit(
      event,
      props.searchQuery,
      props.setIsAutocompleteVisible,
      props.onSubmitRoute,
      props.searchToggle,
      props.navigate,
    ),
});

export const withUpdatePolicy = shouldUpdate((props, nextProps) => {
  // set the search query value to the initial query value if it not set yet and different
  if (
    props.initialQuery &&
    nextProps.searchQuery === null &&
    props.initialQuery !== nextProps.searchQuery
  ) {
    nextProps.setSearchQuery(nextProps.initialQuery);
  }

  // set the search query value to the initial query value if it has changed
  if (
    props.initialQuery &&
    nextProps.initialQuery &&
    props.initialQuery !== nextProps.initialQuery
  ) {
    nextProps.setSearchQuery(nextProps.initialQuery);
  }

  // reset autocomplete selected index on query changes
  if (props.searchQuery !== nextProps.searchQuery) {
    nextProps.setAutocompleteSelectedIndex(-1);
  }

  return true;
});

const debounceSearchQuery = debounce((props, value) => {
  props.setDebouncedSearchQuery(value);
}, AUTOCOMPLETE_DEBOUNCE_TIME);

export const withExtendedHandlers = withHandlers({
  searchInputRef: (props) => (el) => {
    if (el) el.focus();
    if (el && !props.inputReference) props.setInputReference(el);
  },
  updateSearchQuery: (props) => (event) => {
    props.setSearchQuery(event.target.value);
    props.setIsAutocompleteVisible(event.target.value.length > 0);
    debounceSearchQuery(props, event.target.value);
  },
  resetSearchQuery: (props) => () => {
    props.setSearchQuery('');
    props.setIsInitialQueryValid(false);
    props.setIsAutocompleteVisible(false);
    props.inputReference.focus();
  },
  onBeforeNavigate: (props) => () => {
    doHandleSearchSuggestionsClickTracking(props.searchQuery);
    props.setSearchQuery('');
    props.setIsAutocompleteVisible(false);
    props.hideSearch();
    props.inputReference.focus();
  },
});

export const mapDispatchToProps = {
  searchToggle: searchToggleAction,
};

export const withStoreConnection = connect(undefined, mapDispatchToProps);

export const withAutocompleteSelectedIndex = withState(
  'autocompleteSelectedIndex',
  'setAutocompleteSelectedIndex',
  -1,
);

export const withInitialQueryValidState = withState(
  'isInitialQueryValid',
  'setIsInitialQueryValid',
  true,
);

export const withSearchQueryState = withState(
  'searchQuery',
  'setSearchQuery',
  null,
);

export const withAutocompleteVisibleState = withState(
  'isAutocompleteVisible',
  'setIsAutocompleteVisible',
  false,
);

export const withInputReferenceState = withState(
  'inputReference',
  'setInputReference',
  null,
);

export const withDebouncedSearchQueryState = withState(
  'debouncedSearchQuery',
  'setDebouncedSearchQuery',
  '',
);
