import { getServiceUrl } from '../helpers/serviceUrl';
import { log } from '../helpers/utils';
import {
  PUBLICATION_BEOBACHTER,
  PUBLICATION_BIL,
  PUBLICATION_CASH,
  PUBLICATION_GROUP_BEO,
  PUBLICATION_GROUP_BIL,
  PUBLICATION_GROUP_CASH,
  PUBLICATION_GROUP_GM,
  PUBLICATION_GROUP_HZ,
  PUBLICATION_GROUP_ILE,
  PUBLICATION_GROUP_PME,
  PUBLICATION_GROUP_SI,
  PUBLICATION_GROUP_TELE,
  PUBLICATION_HZ,
  PUBLICATION_ILE,
  PUBLICATION_PME,
  PUBLICATION_TELE,
} from '../constants/publications';
import { SubscribeButtonToastService } from '../../common/components/SubscribeButton/typings';

export const SET_ALERT_LIST_DATA = 'alertList/set-alertList-data';
export const ADD_ALERT_LIST_ITEM = 'alertList/add-alertList-item';
export const REMOVE_ALERT_LIST_ITEM = 'alertList/remove-alertList-item';

type AlertListStateActionTypes =
  | 'alertList/set-alertList-data'
  | 'alertList/add-alertList-item'
  | 'alertList/remove-alertList-item';

export type AlertListStateAction = {
  type: AlertListStateActionTypes;
  payload: AlertListState | string | [];
};

// TODO: move this mapping to a more global file
export const APP_NAME_ALERT_PUBLICATION_MAPPING = {
  [PUBLICATION_BEOBACHTER]: PUBLICATION_GROUP_BEO,
  [PUBLICATION_HZ]: PUBLICATION_GROUP_HZ,
  [PUBLICATION_TELE]: PUBLICATION_GROUP_TELE,
  [PUBLICATION_BIL]: PUBLICATION_GROUP_BIL,
  ['gaultmillau']: PUBLICATION_GROUP_GM,
  ['schweizer-illustrierte']: PUBLICATION_GROUP_SI,
  [PUBLICATION_PME]: PUBLICATION_GROUP_PME,
  [PUBLICATION_ILE]: PUBLICATION_GROUP_ILE,
  [PUBLICATION_CASH]: PUBLICATION_GROUP_CASH,
};

export const setAlertListData = (
  data: AlertListState,
): AlertListStateAction => ({
  type: SET_ALERT_LIST_DATA,
  payload: data,
});

export const addAlertListData = (
  alertListItem: string,
): AlertListStateAction => ({
  type: ADD_ALERT_LIST_ITEM,
  payload: alertListItem,
});

export const removeAlertListData = (
  alertListItem: string,
): AlertListStateAction => ({
  type: REMOVE_ALERT_LIST_ITEM,
  payload: alertListItem,
});

const getHeaders = (deviceId: string) => {
  return deviceId?.length ? { 'x-device-id': deviceId } : {};
};

export const fetchAlertList = (deviceId: string) => (dispatch: Function) => {
  fetch(
    `${getServiceUrl(__ALERTS_SERVICE_ENDPOINT__)}/subscriptions/${
      APP_NAME_ALERT_PUBLICATION_MAPPING[__APP_NAME__]
    }`,
    {
      headers: getHeaders(deviceId),
      credentials: 'include',
    },
  )
    .then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        return res.json();
      }
      return [];
    })
    .then((res: AlertListState) => {
      dispatch(setAlertListData(res));
    })
    .catch((error) => {
      log('alertList fetch action', ['error catched:', error], 'red');
    });
};

export const subscribeToAlert =
  (
    type: string,
    id: number,
    ToastService: SubscribeButtonToastService,
    setIsActive,
    deviceId: string,
  ) =>
  (dispatch: Function) => {
    fetch(
      `${getServiceUrl(__ALERTS_SERVICE_ENDPOINT__)}/subscriptions/${
        APP_NAME_ALERT_PUBLICATION_MAPPING[__APP_NAME__]
      }/${type}/${id}`,
      {
        method: 'POST',
        headers: getHeaders(deviceId),
        credentials: 'include',
      },
    )
      .then(({ status }) => {
        switch (status) {
          case 200:
          case 201:
            dispatch(addAlertListData(`${type}-${id}`));
            setIsActive(true);
            break;
          case 403:
            ToastService.displayAuthenticationErrorToast();
            break;
          case 420:
            deviceId?.length
              ? ToastService.displayPushLimitExceededToast()
              : ToastService.displayLimitExceededToast();
            break;
          default:
            ToastService.displayDefaultErrorToast();
        }
      })
      .catch((error) => {
        ToastService.displayDefaultErrorToast();
        log('alertList subscribe action', ['error catched:', error], 'red');
      });
  };

export const unsubscribeToAlert =
  (
    type: string,
    id: number,
    ToastService: SubscribeButtonToastService,
    setIsActive,
    deviceId: string,
  ) =>
  (dispatch: Function) => {
    fetch(
      `${getServiceUrl(__ALERTS_SERVICE_ENDPOINT__)}/subscriptions/${
        APP_NAME_ALERT_PUBLICATION_MAPPING[__APP_NAME__]
      }/${type}/${id}`,
      {
        method: 'DELETE',
        headers: getHeaders(deviceId),
        credentials: 'include',
      },
    )
      .then(({ status }) => {
        switch (status) {
          case 200:
            dispatch(removeAlertListData(`${type}-${id}`));
            setIsActive(false);
            break;
          case 403:
            ToastService.displayAuthenticationErrorToast();
            break;
          case 420:
            deviceId.length
              ? ToastService.displayPushLimitExceededToast()
              : ToastService.displayLimitExceededToast();
            break;
          default:
            ToastService.displayDefaultErrorToast();
        }
      })
      .catch((error) => {
        ToastService.displayDefaultErrorToast();
        log('alertList unsubscribe action', ['error catched:', error], 'red');
      });
  };
