import React from 'react';
import { SocialIconProps } from '../../typings';

const YouTubeIcon = ({
  className,
  classNameGradient,
  classNameGradientInner,
  id,
}: Omit<SocialIconProps, 'type'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    className={className}
  >
    <g fill={`url(#${id})`} className={classNameGradient}>
      <g className={classNameGradientInner}>
        <path d="M5.764 11.782h.87v4.708h.823v-4.708h.886v-.77h-2.58M12.723 12.357c-.272 0-.526.15-.765.443v-1.79h-.74v5.48h.74v-.396c.248.306.5.452.765.452.294 0 .492-.155.59-.46.05-.172.076-.444.076-.82v-1.627c0-.388-.027-.658-.076-.822-.1-.306-.297-.46-.59-.46zm-.074 2.966c0 .37-.11.55-.323.55-.122 0-.245-.058-.37-.182v-2.487c.125-.122.248-.18.37-.18.213 0 .322.19.322.557v1.743zM9.838 15.527c-.164.232-.32.346-.468.346-.1 0-.155-.058-.173-.173-.007-.023-.007-.114-.007-.287v-3.006h-.74v3.228c0 .29.025.483.066.607.074.207.238.304.476.304.273 0 .552-.164.846-.5v.444h.74v-4.083h-.74v3.12zM10.473 8.635c.24 0 .357-.19.357-.572V6.327c0-.382-.116-.572-.357-.572-.24 0-.357.19-.357.572v1.736c0 .38.116.572.357.572z" />
        <path d="M10.972 0C4.912 0 0 4.912 0 10.972s4.912 10.972 10.972 10.972 10.972-4.912 10.972-10.972S17.032 0 10.972 0zm1.22 5.132h.746V8.17c0 .175 0 .266.01.29.015.116.074.175.174.175.15 0 .307-.115.472-.35V5.132h.748v4.126h-.748v-.45c-.297.34-.58.507-.854.507-.24 0-.407-.098-.482-.307-.04-.125-.066-.322-.066-.613V5.132zM9.37 6.477c0-.44.074-.763.233-.972.206-.282.497-.423.87-.423.375 0 .666.14.872.423.157.208.232.53.232.972v1.445c0 .44-.075.764-.232.97-.206.282-.497.423-.872.423-.373 0-.664-.14-.87-.423-.16-.206-.234-.532-.234-.97V6.477zm-2-2.756l.588 2.175.565-2.174h.84l-1 3.296v2.242h-.827V7.016c-.076-.398-.24-.98-.507-1.752-.176-.514-.358-1.03-.533-1.543h.872zm9.508 13.13c-.15.65-.682 1.132-1.323 1.203-1.518.17-3.054.17-4.584.17-1.528 0-3.065 0-4.583-.17-.64-.07-1.173-.552-1.323-1.203-.213-.926-.213-1.94-.213-2.893 0-.955.004-1.967.217-2.894.15-.65.682-1.13 1.323-1.203 1.518-.17 3.054-.17 4.584-.17 1.53 0 3.066 0 4.584.17.64.072 1.173.552 1.323 1.203.214.927.213 1.94.213 2.894 0 .954 0 1.967-.215 2.893z" />
        <path d="M15.08 12.357c-.38 0-.673.14-.887.42-.157.205-.23.524-.23.96v1.43c0 .435.082.756.24.96.213.28.507.42.894.42s.69-.147.895-.444c.09-.13.148-.28.173-.443.007-.074.015-.238.015-.477v-.107h-.754c0 .296-.01.46-.016.5-.043.198-.15.297-.33.297-.255 0-.38-.188-.38-.566v-.724h1.48v-.846c0-.436-.074-.755-.23-.962-.205-.28-.5-.418-.87-.418zm.36 1.61h-.74v-.378c0-.378.125-.567.372-.567.245 0 .37.19.37.566v.377z" />
      </g>
    </g>
  </svg>
);
export default YouTubeIcon;
