/**
 * @file   piano constants
 */

export const PIANO_LOCAL_STORAGE_PARAMS = 'tp-params';

export const PIANO_EVENT_CHECKOUT_START = 'startCheckout';

export const PIANO_CHECKOUT_CSS_CLASS = 'tp-checkout';

export const PIANO_CONTAINER_ANIMATED = 'piano-animated-container';
export const PIANO_CONTAINER_INLINED = 'piano-inlined';
export const PIANO_CONTAINER_METERING = 'piano-metering';
export const PIANO_CONTAINER_METERING_PADDED = 'piano-metering-padded';
export const PIANO_CONTAINER_LOCKED = 'piano-locked';

export const PIANO_CONTAINER_SLIDE_DOWN_ANIMATED =
  'piano-slide-down-animated-container';

export const PIANO_ALREADY_HAVE_ACCESS_EXPERIENCES: Record<string, any> = {
  // BEO
  cTWFTvB95d: 'OTEVEP9GCXO5',
  BFOCJKZOhU: 'OTGR3TD5YN51',
  // WM
  HWf4CiespE: 'OTXC1LRRBPO9',
  '1SyDVoHY7k': 'OTH4ST0MEZGP',
};

export const PIANO_ORDERBOOK_WIDGET = 'piano-template-orderbook';
export const PIANO_TRADING_TEASER_WIDGET = 'piano-template-trading-teaser';
export const PIANO_CORPORATE_ACTIONS_WIDGET =
  'piano-template-corporate-actions';
export const PIANO_CONTAINER_ARTICLE_ASIDE = 'piano-article-aside';
export const PIANO_CONTAINER_ARTICLE_ASIDE_1 = 'piano-article-aside-1';
export const PIANO_CONTAINER_ARTICLE_ASIDE_2 = 'piano-article-aside-2';
export const PIANO_CONTAINER_ARTICLE_ASIDE_3 = 'piano-article-aside-3';
export const PIANO_CONTAINER_LANDING_ASIDE = 'piano-landing-aside';
export const PIANO_CONTAINER_LANDING_ASIDE_2 = 'piano-landing-aside-2';
export const PIANO_CONTAINER_LANDING_ASIDE_3 = 'piano-landing-aside-3';
export const PIANO_PLACEHOLDER_INLINED = 'PaywallSkeleton';
export const PIANO_PLACEHOLDER_ASIDE = 'PianoPlaceholder';
