import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import raf from 'raf';
import { getRCTrackingSource } from '../../../shared/helpers/getRCTrackingSource';
import { convertUrl, openWebShareAPIDialog } from './helpers';
import authStateSelector from '../../../shared/selectors/authStateSelector';
import pianoStateSelector from '../../../shared/selectors/pianoStateSelector';
import TestFragment from '../../../shared/tests/components/TestFragment';
import {
  addWebAppEventListener,
  removeWebAppEventListener,
} from '../HybridAppProvider';
import {
  COMMENT_STATUS_CLOSED,
  COMMENT_STATUS_HIDDEN,
} from '../../../shared/constants/comments';
import {
  NATIVE_ADVERTISING_CONTENT_TYPE,
  RESTRICTION_STATUS_PAID,
  RESTRICTION_STATUS_REGISTERED,
} from '../../../shared/constants/content';
import {
  UTILITY_BAR_ORIGIN_OVERLAY,
  UTILITY_TYPE_BOOKMARKS,
  UTILITY_TYPE_COMMENTS,
  UTILITY_TYPE_SHARE,
} from '../../../shared/constants/utilitybar';
import {
  UtilityBarFactoryOptions,
  UtilityBarFactoryOptionsStyles,
  UtilityBarProps,
  UtilityBarToastService,
} from './typings';

type UtilityBarPropsInner = UtilityBarProps & {
  headerContentType: string;
  routePathname: string;
  commentCount: number;
  headerArticleData: ArticleData;
  hasSubscriptions: boolean;
  pageMetadata: PianoPageMetadata;
};

const defaultStyles: UtilityBarFactoryOptionsStyles = {
  Wrapper: '',
};

const areCommentsHidden = (
  commentStatus = '',
  commentCount: number,
  headerContentType = '',
) =>
  (commentStatus === COMMENT_STATUS_CLOSED && commentCount <= 0) ||
  commentStatus === COMMENT_STATUS_HIDDEN ||
  headerContentType === NATIVE_ADVERTISING_CONTENT_TYPE;

const UtilityBarFactory = ({
  UtilityLink,
  UtilityBookmarkLink,
  availableUtilities: appAvailableUtilities,
  headerStateSelector,
  locationStateSelector,
  commentStateSelector,
  styles: appStyles,
  ToastService: appToastService,
}: UtilityBarFactoryOptions) => {
  const UtilityBar = (props: UtilityBarPropsInner): ReactElement | null => {
    const [isOverlayVisible, toggleOverlayVisible] = useState(false);
    const [visibleId, setVisibleId] = useState('');
    const isOverlayVisibleRef = useRef(false);
    const visibleIdRef = useRef('');

    const isHybridApp = useSelector(
      (state) => locationStateSelector(state).isHybridApp,
    );

    visibleIdRef.current = visibleId;
    isOverlayVisibleRef.current = isOverlayVisible;
    // this helper function is only here to add/remove listener on body as named function
    const toggleVisibility = () =>
      raf(() => {
        if (isOverlayVisibleRef.current && global.innerWidth > 759) {
          setVisibleId('');
          toggleOverlayVisible(false);
        }
      });

    useEffect(() => {
      if (isOverlayVisibleRef.current) {
        const mainElement = document.querySelector('main');

        if (mainElement) {
          mainElement.addEventListener('click', toggleVisibility, {
            once: true,
          });
        }
      }
    }, []);

    const styles: UtilityBarFactoryOptionsStyles =
      (typeof appStyles === 'function' && appStyles(props)) ||
      (typeof appStyles === 'object' && appStyles) ||
      defaultStyles;

    const {
      enabledUtilities,
      headerArticleData,
      headerContentType,
      routePathname,
      commentCount,
      children,
      origin,
      theme,
      shareUrl,
      title,
      shortTitle,
      lead,
      socialMediaTitle,
      imageUrl,
      hasSubscriptions = false,
      pageMetadata,
      hideIconLabel = false,
    } = props;

    useEffect(() => {
      setVisibleId('');
      toggleOverlayVisible(false);
    }, [routePathname]);

    const source = getRCTrackingSource('bookmark', pageMetadata);

    const ToastService: UtilityBarToastService =
      (typeof appToastService === 'function' && appToastService({ source })) ||
      (typeof appToastService === 'object' && appToastService);

    const utilityTitle = title || headerArticleData?.title || '';
    const utilityShortTitle = shortTitle || headerArticleData?.shortTitle || '';
    const utilityLead = lead || headerArticleData?.lead || '';
    const utilitySocialMediaTitle =
      socialMediaTitle || headerArticleData?.socialMediaTitle || '';
    const utilityShareUrl =
      shareUrl || headerArticleData?.preferredUri || routePathname;
    const availableUtilities =
      (typeof appAvailableUtilities === 'function' &&
        appAvailableUtilities(props)) ||
      appAvailableUtilities;

    const isRestricted =
      [RESTRICTION_STATUS_PAID, RESTRICTION_STATUS_REGISTERED].includes(
        headerArticleData?.restrictionStatus,
      ) && !hasSubscriptions;

    const filteredItems =
      Array.isArray(availableUtilities) &&
      availableUtilities
        .filter(
          (item) =>
            // Item is enabled
            enabledUtilities &&
            enabledUtilities.indexOf(item.id) > -1 &&
            // Item is of type 'comment' and NOT hidden from view (closed w/o comments or hidden)
            !(
              item.id === UTILITY_TYPE_COMMENTS &&
              areCommentsHidden(
                headerArticleData?.commentStatus,
                commentCount,
                headerContentType,
              )
            ),
        )
        .sort(
          (a, b) =>
            enabledUtilities.indexOf(a.id) - enabledUtilities.indexOf(b.id),
        );
    let shareClickHandler;
    filteredItems &&
      filteredItems.map((item) => {
        if (item.id === UTILITY_TYPE_SHARE) {
          // @ts-ignore
          shareClickHandler = (event: Event) =>
            openWebShareAPIDialog({
              event,
              title: utilityTitle,
              lead: utilityLead,
              url:
                (global?.location?.protocol &&
                  global?.location?.host &&
                  utilityShareUrl &&
                  `${global.location.protocol}//${global.location.host}${utilityShareUrl}`) ||
                global?.location?.href ||
                '',
              isHybridApp,
              fallback: () =>
                raf(() => {
                  if (!isOverlayVisible) {
                    setVisibleId(item.id);
                    toggleOverlayVisible(true);
                  } else {
                    if (isOverlayVisible) {
                      if (visibleId !== item.id) {
                        setVisibleId(item.id);
                        toggleOverlayVisible(true);
                      } else {
                        setVisibleId('');
                        toggleOverlayVisible(false);
                      }
                    }
                  }
                }),
            });
        }
      });
    useEffect(() => {
      if (shareClickHandler) {
        addWebAppEventListener('handle-share-click', shareClickHandler);
      }
      return () => {
        if (shareClickHandler) {
          removeWebAppEventListener('handle-share-click', shareClickHandler);
        }
      };
    }, [shareClickHandler]);

    if (
      !enabledUtilities ||
      !Array.isArray(enabledUtilities) ||
      enabledUtilities.length <= 0
    ) {
      return null;
    }

    if (!filteredItems || filteredItems.length <= 0) {
      return <TestFragment data-testid="utility-bar-no-enabled-items" />;
    }

    return (
      <>
        <div
          className={classNames(styles.Wrapper, {
            ['utility-bar']: origin !== UTILITY_BAR_ORIGIN_OVERLAY,
          })}
        >
          {filteredItems.map((item) => {
            const url = convertUrl({
              url: item.url,
              shareUrl: utilityShareUrl,
              title: utilityTitle,
              shortTitle: utilityShortTitle,
              lead: utilityLead,
              socialMediaTitle: utilitySocialMediaTitle,
              additionalQueryParam: item.referrer,
              imageUrl,
              hasSponsoredContentPrefix:
                headerContentType === NATIVE_ADVERTISING_CONTENT_TYPE,
              isHybridApp,
            });

            if (item.id === UTILITY_TYPE_SHARE) {
              // @ts-ignore
              item.onClick = (event: Event) =>
                openWebShareAPIDialog({
                  event,
                  title: utilityTitle,
                  lead: utilityLead,
                  url:
                    (global?.location?.protocol &&
                      global?.location?.host &&
                      utilityShareUrl &&
                      `${global.location.protocol}//${global.location.host}${utilityShareUrl}`) ||
                    global?.location?.href ||
                    '',
                  isHybridApp,
                  fallback: () =>
                    raf(() => {
                      if (!isOverlayVisible) {
                        setVisibleId(item.id);
                        toggleOverlayVisible(true);
                      } else {
                        if (isOverlayVisible) {
                          if (visibleId !== item.id) {
                            setVisibleId(item.id);
                            toggleOverlayVisible(true);
                          } else {
                            setVisibleId('');
                            toggleOverlayVisible(false);
                          }
                        }
                      }
                    }),
                });
            }

            if (item.toggleCustomOverlay) {
              item.onClick = () => {
                raf(() => {
                  if (
                    visibleIdRef.current === item.id &&
                    isOverlayVisibleRef.current
                  ) {
                    setVisibleId('');
                    toggleOverlayVisible(false);
                  } else {
                    if (visibleIdRef.current && isOverlayVisibleRef.current) {
                      toggleOverlayVisible(false);
                    }
                    setVisibleId(item.id);
                    toggleOverlayVisible(true);
                  }
                });
              };
            }

            const itemProps = {
              item,
              url,
              origin,
              theme,
              isRestricted,
              hideIconLabel,
              toastService: ToastService || null,
              isActive:
                item.id === visibleIdRef.current && isOverlayVisibleRef.current,
              commentCount:
                (headerArticleData?.commentStatus !== COMMENT_STATUS_HIDDEN &&
                  item.id === UTILITY_TYPE_COMMENTS &&
                  commentCount > 0 &&
                  commentCount) ||
                null,
            };

            if (item.id === UTILITY_TYPE_BOOKMARKS) {
              return (
                <UtilityBookmarkLink
                  key={`utility-bar-${item.id}-${itemProps.isActive}-${item.iconType}-${headerArticleData?.gcid}`}
                  id={headerArticleData?.gcid}
                  item={item}
                  origin={origin}
                  theme={theme}
                  isRestricted={isRestricted}
                  toastService={ToastService}
                  trackingSource={source}
                  hideIconLabel={hideIconLabel}
                />
              );
            }

            if (typeof item.OverwriteUtilityLink === 'function') {
              return (
                <item.OverwriteUtilityLink
                  key={`utility-bar-${item.id}-${itemProps.isActive}-${item.iconType}`}
                  {...itemProps}
                />
              );
            }
            return (
              <UtilityLink
                key={`utility-bar-${item.id}-${itemProps.isActive}-${item.iconType}`}
                {...itemProps}
              />
            );
          })}
        </div>
        {children &&
          children({ isOverlayVisible, toggleOverlayVisible, visibleId })}
      </>
    );
  };

  const mapStateToProps = (state) => ({
    headerArticleData: headerStateSelector(state).articleData,
    headerContentType: headerStateSelector(state).contentType,
    hasSubscriptions:
      (state.auth && authStateSelector(state).hasSubscriptions) || null,
    commentCount: (state.comment && commentStateSelector(state).count) || -1,
    routePathname:
      locationStateSelector(state).locationBeforeTransitions.pathname,
    pageMetadata: (state.piano && pianoStateSelector(state).pageMetadata) || {},
  });

  return connect(mapStateToProps)(UtilityBar);
};

export default UtilityBarFactory;
