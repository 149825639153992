/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { PUBLICATION_ID_DE, PUBLICATION_ID_FR } from '../../../../constants';
import { GET_PERSON_LIST_QUERY } from './queries';
import { RasRouterProps } from '../../../../components/Router/typings';

export const apolloConfig: RaschApolloConfig<RasRouterProps> = {
  options: ({ params, props }) => {
    const { char = 'A' } = params;
    const { language } = props;
    return {
      query: GET_PERSON_LIST_QUERY,
      variables: {
        char: char && char.toUpperCase(),
        publication: language === 'fr' ? PUBLICATION_ID_FR : PUBLICATION_ID_DE,
      },
    };
  },
};
