/**
 * @file    admin user constants
 */

// admin enum states for User policies in comments
export const COMMENTS_ANCHOR_ID = 'comments';
export const COMMENT_STATUS_OPEN = 'open';
export const COMMENT_STATUS_CLOSED = 'closed';
export const COMMENT_STATUS_HIDDEN = 'hidden';
export const COMMENT_SECTION_ID = 'comment-section';
export const COMMENT_SIZE = 4;
export const COMMENT_REDAKTION_STYLE = 'Style-Redaktion';
export const COMMENT_REDAKTION_SI = 'SI-Redaktion';
export const COMMENT_REDAKTION_PME = 'PME-Redaktion';
export const COMMENT_REDAKTION_ILE = 'ILE-Redaktion';

export const TRACKING_COMMENT_TYPE = 'comment';
export const TRACKING_ANSWER_TYPE = 'answer';
