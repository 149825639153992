// TODO:  add unit test

// include polyfill
// https://github.com/WICG/IntersectionObserver/tree/gh-pages/polyfill
import 'helpers/intersection-observer';

import { useDebugValue, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { cssSelectorFromElement } from './useScrollToLinkElement';
import locationStateSelector from '../selectors/locationStateSelector';
import windowStateSelector from '../selectors/windowStateSelector';

export interface UseInViewConfig extends InViewConfig {
  isInitialInView?: boolean;
}

type UseInViewState = {
  isInView: boolean;
  entry?: IntersectionObserverEntry;
};

export type UseInViewResponse = {
  ref: HTMLElement;
  setRef: React.Dispatch<HTMLElement>;
  isInView: boolean;
  entry: IntersectionObserverEntry;
  reInitOnViewportLabelChange?: boolean;
  reInitOnLocationChange?: boolean;
};

const isIntersectionObserverSupported =
  'IntersectionObserver' in global && __CLIENT__;

const elementCssSelector = new Set();

const useInView = (
  options: UseInViewConfig = {
    rootMargin: '',
    threshold: [0],
  },
  isObserveDelayed = false,
  isInitialInView = false,
  reInitOnViewportLabelChange = false, // INFO: this prop is not allowed when then observer config includes a root element, otherwise the observer will be re-initialized and thus overwrite the root element.
  reInitOnLocationChange = false,
): UseInViewResponse => {
  const viewportLabel: ViewportLabel = useSelector(
    (state) => windowStateSelector(state).viewport.label,
    shallowEqual,
  );
  const screenReady: boolean = useSelector(
    (state) => locationStateSelector(state).screenReady,
    shallowEqual,
  );
  const pathname: string = useSelector(
    (state) => locationStateSelector(state).locationBeforeTransitions.pathname,
    shallowEqual,
  );
  const isInitialPage: boolean = useSelector(
    (state) => locationStateSelector(state).isInitialPage,
    shallowEqual,
  );
  const action = useSelector(
    (state) => locationStateSelector(state).locationBeforeTransitions.action,
  );

  const isPop = action === 'POP' && !isInitialPage;

  // TODO: implement a useReducer instead
  const [state, setState] = useState<UseInViewState>({
    isInView: (__TESTING__ && true) || isPop || isInitialInView,
    entry: undefined,
  });

  // hooks
  const [ref, setRef] = useState<HTMLElement>(null);
  const callbackRef = useRef((entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      let isInView = entry.isIntersecting || false;
      if (
        elementCssSelector.has(cssSelectorFromElement(entry.target)) &&
        isPop
      ) {
        isInView = true;
      }
      setState({ isInView, entry });

      if (isInView) {
        elementCssSelector.add(cssSelectorFromElement(entry.target));
      }

      if (isInView && options.triggerOnce && entry.target) {
        observer?.unobserve?.(entry.target);
      }
    });
  });

  const [observer, setObserver] = useState(() => {
    if (!isIntersectionObserverSupported) {
      return null;
    }

    const observerRef = new IntersectionObserver(callbackRef.current, {
      // INFO: we dont need the "root: options.root" here, because the DOM element isn't initialized at this time.
      // see "observer.observe(document.querySelector(options.root));" below
      rootMargin: options.rootMargin || '0px',
      threshold: options.threshold || 0,
    });

    return observerRef;
  });

  useEffect(() => {
    if (reInitOnViewportLabelChange) {
      if (ref && isIntersectionObserverSupported && observer) {
        observer.unobserve(ref);
      }
      // TODO: get rid of this, this triggers the re-init of the observer
      setObserver(() => {
        return new IntersectionObserver(callbackRef.current, {
          // INFO: we dont need the "root: options.root" here, because the DOM element isn't initialized at this time.
          // see "observer.observe(document.querySelector(options.root));" below
          rootMargin: options.rootMargin || '0px',
          threshold: options.threshold || 0,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportLabel, reInitOnViewportLabelChange, options, ref]);

  useEffect(() => {
    if (reInitOnLocationChange && screenReady && !isInitialPage) {
      observer.unobserve(ref);

      setTimeout(() => {
        const rootEl: HTMLElement =
          typeof options?.root === 'string'
            ? document.querySelector(options.root)
            : null;

        if (rootEl) {
          observer.observe(document.querySelector(options.root));
          setRef(rootEl);
        } else {
          observer.observe(ref);
        }
      }, 100);
    }
  }, [
    screenReady,
    pathname,
    reInitOnLocationChange,
    options,
    isInitialPage,
    ref,
    observer,
  ]);

  useEffect(() => {
    if (ref && isIntersectionObserverSupported) {
      // The isObserveDelayed prop was added after we faced some issues with the timing of the intersection-observer.
      // The intersection-observer got initialized before the DOM was ready, which lead to the problem that isInView was always true for a short time on page-change.

      if (isObserveDelayed) {
        setTimeout(() => {
          const rootEl =
            typeof options?.root === 'string'
              ? document.querySelector(options.root)
              : null;
          if (rootEl) {
            observer.observe(document.querySelector(options.root));
          } else {
            observer.observe(ref);
          }
        }, 1500);
      } else {
        observer.observe(ref);
      }
    }

    return () => {
      if (ref && isIntersectionObserverSupported) {
        observer.unobserve(ref);
      }
    };
  }, [
    ref,
    options.threshold,
    options.root,
    options.rootMargin,
    options.triggerOnce,
    isObserveDelayed,
    observer,
  ]);

  useDebugValue(state.isInView);

  return {
    ref,
    setRef,
    isInView: state.isInView,
    entry: state.entry || null,
  };
};

export default useInView;
