import {
  TYPE_AD,
  TYPE_TEASER,
} from '../../../../../../../common/components/TeaserGrid/gridConfigs/constants';
import { WB_1 } from '../../../../../../../shared/constants/adZone';
import { TEASER_LAYOUT_ML } from '../../../../../../../shared/constants/teaser';
import { MMR_1 } from '../../../AppNexus/constants';

export const mixedWithAd = {
  gridGroups: [
    {
      config: {
        hasContainer: true,
      },
      items: [
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
        },
        {
          type: TYPE_AD,
          adConfig: [
            { slot: WB_1, deviceType: 'tabletDesktop' },
            { slot: MMR_1, deviceType: 'mobile' },
          ],
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_ML,
        },
      ],
    },
  ],
};
