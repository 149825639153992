import { ApolloLink, HttpLink, Observable } from '@apollo/client';
import { Operation } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { removeDirectivesFromDocument } from '@apollo/client/utilities';
import { sha256 } from 'crypto-hash';
import { OperationDefinitionNode, StringValueNode } from 'graphql';
import fetch from 'node-fetch';
import { hashCode } from './helpers/nonSecureHasher';
import { getServiceUrl } from './helpers/serviceUrl';
import { Auth0 } from '../common/components/Auth0Provider';
import { getQueryDocumentKey } from './getQueryDocumentKey';
import fallbackJson from './graphql-mocks/451.json';
import queryMapData from './queries';

const config = {
  endpoints: {
    cms: {
      default: String(process.env.__CMS_GRAPHQL_HOST__),
      preview: String(
        process.env.__CMS_PREVIEW_GRAPHQL_HOST__ ||
          process.env.__CMS_GRAPHQL_HOST__,
      ),
    },
    'graphql-service': {
      default: String(process.env.__GRAPHQL_HOST__),
      preview: String(
        process.env.__PREVIEW_GRAPHQL_HOST__ || process.env.__GRAPHQL_HOST__,
      ),
    },
  },
  defaultEndpoint: String(process.env.__DEFAULT_GRAPHQL_ENDPOINT__),
};

// ----------------------------------------------

const getDirectiveArgumentValueFromOperation = (
  operation: Operation,
  directiveName: string,
  argumentName: string,
) =>
  (
    (
      operation.query.definitions.find(
        (definition) => definition.kind === 'OperationDefinition',
      ) as OperationDefinitionNode
    )?.directives
      ?.find((directive) => directive.name?.value === directiveName)
      ?.arguments?.find((argument) => argument.name?.value === argumentName)
      ?.value as StringValueNode
  )?.value || config.defaultEndpoint;

const getApiDirectiveValue = (operation: Operation) => {
  return getDirectiveArgumentValueFromOperation(operation, 'api', 'name');
};

const getEndpointUri = ({
  operation,
  host,
  req,
}: {
  operation: Operation;
  host: string;
  req: Request;
}) => {
  const apiName = getApiDirectiveValue(operation);

  const env =
    host.includes('preview.') || __FORCE_PREVIEW_REQUESTS__
      ? 'preview'
      : 'default';

  if (!config.endpoints[apiName][env]) {
    throw new Error(`No endpoint found for ${apiName}`);
  }

  return getServiceUrl(config.endpoints[apiName][env], req);
};

const createContext = ({
  operation,
  host,
  req,
}: {
  operation: Operation;
  host: string;
  req: Request;
}) => {
  // get endpoint uri by reading the @api directive
  const endpointUri = getEndpointUri({ operation, host, req });
  const apiName = getApiDirectiveValue(operation);

  if (!endpointUri) {
    throw new Error('Was not able to find endpoint URI!');
  }

  const modifiedOperation = removeCustomDirectives(operation);

  if (!modifiedOperation.query) {
    throw new Error('Error while removing directive @api');
  }

  // set correct endpoint uri
  modifiedOperation.setContext({
    uri: endpointUri,
    apiName,
  });

  return modifiedOperation;
};

const removeCustomDirectives = (operation: Operation) => {
  return Object.assign(operation, {
    query: removeDirectivesFromDocument(
      [{ name: 'api', remove: true }],
      operation.query,
    ),
  });
};

const getDataForGetRequest = (operation: Operation) => {
  const queryDocument: Operation = operation.query as any;

  const queryKey: string = hashCode(getQueryDocumentKey(queryDocument));

  queryKey === undefined &&
    // eslint-disable-next-line
    console.error(
      'multiEntryApolloLinks hash',
      'hashCode undefined',
      JSON.stringify(queryDocument),
    );

  if (
    !(queryKey in queryMapData.map) ||
    queryMapData.map[queryKey] === undefined
  ) {
    // eslint-disable-next-line
    console.error(
      'multiEntryApolloLinks queryMapData',
      `query: ${JSON.stringify(queryDocument)}`,
      `queryKey: ${JSON.stringify(queryKey)}`,
      `queryMapData version: ${JSON.stringify(queryMapData.version)}`,
      `Object keys: ${JSON.stringify(Object.keys(queryMapData.map))}`,
      `Object IDs: ${JSON.stringify(Object.values(queryMapData.map))}`,
    );
  }

  return {
    id: queryMapData.map[queryKey],
    version: queryMapData.version,
    variables: operation.variables,
    operationName: operation.operationName,
  };
};

const parseAndCheckResponse = (operation) => (response: Response) => {
  return response
    .json()
    .then((result) => {
      // handle status code
      if (response.status >= 300)
        throw new Error(
          `Response not successful: Received status code ${response.status}`,
        );

      // extract data if we receive an array (just use first item)
      const data: any = (Array.isArray(result) && result[0]) || result;

      // validate data
      if (!data.hasOwnProperty('data') && !data.hasOwnProperty('errors')) {
        throw new Error(
          `Server response was missing for query '${operation.operationName}'.`,
        );
      }

      return data;
    })
    .catch((e) => {
      const httpError: any = new Error(
        `Network request failed with status ${response.status} - "${response.statusText}"`,
      );
      httpError.response = response;
      httpError.parseError = e;
      httpError.statusCode = response.status;

      throw httpError;
    });
};

const getRequestHeaders = ({
  predefinedHeaders = {},
  isAuthorizationHeaderRequired = false,
}) => {
  const headers: any = {
    ...predefinedHeaders,
    accept: 'application/json',
    'content-type': 'application/json', // must be set to a value which qualifies as "simple" according to https://fetch.spec.whatwg.org/#cors-protocol-and-credentials. atm switched back to application/json because of issue when running requests through akamai
  };

  // add authorization header
  const token = (isAuthorizationHeaderRequired && Auth0.getAccessToken()) || '';
  if (token && isAuthorizationHeaderRequired) {
    headers.authorization = `Bearer ${token}`;
  }

  return headers;
};

const isMutation = (operation: Operation) => {
  if (Array.isArray(operation?.query?.definitions)) {
    return operation.query.definitions.some((d) => {
      return d.kind === 'OperationDefinition' && d.operation === 'mutation';
    });
  }
  return false;
};

// ----------------------------------------------

/**
 * We have to use here sha256 implementation.
 * https://github.com/apollographql/apollo-server/issues/2894
 * The intention of the client API was to provide a different implementation of SHA 256.
 * We are not looking to provide a custom hash implementation on the server-side.
 */
const persistedQueryLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

//TODO: geo blocking - verify this error handler
//https://jira.ringieraxelspringer.ch/browse/BG-341
const errorLink = onError((error) => {
  if (
    error.networkError &&
    'statusCode' in error.networkError &&
    error.networkError.statusCode === 450
  ) {
    error.networkError = undefined;
    error.response = { data: fallbackJson };
  }
});

class HeadersLink extends ApolloLink {
  private fetchAfterware: (any) => any | null;
  constructor(fetchAfterware) {
    super();
    this.fetchAfterware = fetchAfterware;
  }
  request(operation, forward) {
    return forward(operation).map((data) => {
      if (this.fetchAfterware) {
        this.fetchAfterware({
          response: operation.getContext().response,
          operationName: operation.operationName,
          variables: operation.variables,
        });
      }
      return data;
    });
  }
}

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: ({ statusCode, result }) => {
      const messages =
        (result?.errors && result.errors.map((e) => e.message).join(', ')) ||
        '';
      return (
        statusCode >= 500 &&
        statusCode !== 504 &&
        messages.indexOf('PersistedQueryNotFound') === -1
      );
    },
  },
  delay: {
    initial: 2000,
    max: Infinity,
    jitter: true,
  },
});

class HttpGetLink extends ApolloLink {
  private fetchAfterware;
  private headers;

  constructor({ headers, fetchAfterware }) {
    super();
    this.fetchAfterware = fetchAfterware;
    this.headers = headers;
  }

  request(operation: Operation) {
    return new Observable((observer) => {
      const requestData = getDataForGetRequest(operation);

      const variables = { ...requestData.variables };

      for (const [key, value] of Object.entries(variables)) {
        if (typeof value === 'string') {
          variables[key] = encodeURIComponent(value);
        }
      }

      const requestUri = `${operation.getContext().uri}?version="${
        requestData.version
      }"&id="${requestData.id}"&operationName="${
        requestData.operationName
      }"&variables=${JSON.stringify(variables)}`.replace(/%22/g, '\\"');

      fetch(requestUri, {
        headers: getRequestHeaders({ predefinedHeaders: this.headers }),
      })
        .then((response) => {
          operation.setContext({ response });

          // run fetch afterware
          if (
            this.fetchAfterware &&
            typeof this.fetchAfterware === 'function'
          ) {
            this.fetchAfterware({
              response,
              operationName: requestData.operationName,
              variables,
            });
          }
          return response;
        })
        .then(parseAndCheckResponse(operation))
        .then((response) => {
          observer.next(response);
          observer.complete();
        })
        .catch(observer.error.bind(observer));
    });
  }
}

class HttpPostLink extends ApolloLink {
  private httpLink: HttpLink;
  private headers: any;

  constructor({ headers }) {
    super();
    this.httpLink = new HttpLink({ fetch });
    this.headers = headers;
  }

  request(operation, forward) {
    // set headers
    operation.setContext({
      headers: getRequestHeaders({
        predefinedHeaders: this.headers,
        isAuthorizationHeaderRequired: isMutation(operation),
      }),
    });

    // TEMP: we include cookies on request if the query name contains `Search` (Search or SearchCategory)
    // as soon as we switch to a skeleton approach, we can remove this
    if (
      operation.operationName.indexOf('WithCredentials') > -1 &&
      operation.getContext().apiName !== 'cms'
    ) {
      operation.setContext({ credentials: 'include' });
    }

    const requestData = getDataForGetRequest(operation);
    const variables = { ...requestData.variables };

    if (operation.getContext().apiName === 'cms' && variables) {
      for (const [key, value] of Object.entries(variables)) {
        if (typeof value === 'string') {
          variables[key] = encodeURIComponent(value);
        }
      }
      operation.variables = variables;
    }

    return this.httpLink.request(operation, forward);
  }
}

class ContextLink extends ApolloLink {
  private host: string;
  private req: Request;

  constructor({ host, req }) {
    super();
    this.host = host;
    this.req = req;
  }

  request(operation, forward) {
    return forward(
      createContext({ operation, host: this.host, req: this.req }),
    );
  }
}

// ----------------------------------------------

const multiEntryApolloLinks = ({ fetchAfterware, headers, host, req }) => {
  return ApolloLink.from([
    // read @api directive and create context
    new ContextLink({ host, req }), // MUST be the FIRST link!

    // for graphql-service requests we use the error link
    ApolloLink.split((operation) => {
      const apiName = operation.getContext().apiName;
      return apiName === 'graphql-service';
    }, errorLink),

    // for graphql-service requests we use APQ link
    ApolloLink.split((operation) => {
      const apiName = operation.getContext().apiName;
      const locationOrigin = global.locationOrigin || '';
      return (
        apiName === 'graphql-service' &&
        locationOrigin.indexOf('dev.local:') === -1
      );
    }, persistedQueryLink),

    // for graphql-service requests on the client we use the retry link
    ApolloLink.split((operation) => {
      const apiName = operation.getContext().apiName;
      return apiName === 'graphql-service' && __CLIENT__;
    }, retryLink),

    // for graphql-service requests we use the headers link
    ApolloLink.split((operation) => {
      const apiName = operation.getContext().apiName;
      return apiName === 'graphql-service';
    }, new HeadersLink(fetchAfterware)),

    // for CMS production query requests we use GET
    // for all other requests incl. mutations we use POST
    ApolloLink.split(
      (operation) => {
        const apiName = operation.getContext().apiName;

        const forcePostRequests =
          JSON.parse(process.env.__GRAPHQL_FORCE_POST__ || 'false') ||
          isMutation(operation) ||
          false;

        const isProduction =
          process.env.NODE_ENV === 'production' && !forcePostRequests;
        return apiName === 'cms' && isProduction;
      },
      new HttpGetLink({ headers, fetchAfterware }),
      new HttpPostLink({ headers }),
    ),
  ]);
};

export default multiEntryApolloLinks;
