import React, { MouseEvent, ReactElement, memo } from 'react';
import classNames from 'classnames';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import Link from '../../../LinkLegacy';
import {
  UTILITY_BAR_ORIGIN_OVERLAY,
  UTILITY_TYPE_BOOKMARKS,
  UTILITY_TYPE_CALCULATOR,
  UTILITY_TYPE_COMMENTS,
  UTILITY_TYPE_CONTACT,
  UTILITY_TYPE_DOWNLOAD,
  UTILITY_TYPE_INFO,
  UTILITY_TYPE_PRINT,
} from '../../../../../shared/constants/utilitybar';
import {
  UtilityLinkFactoryOptions,
  UtilityLinkFactoryOptionsStyles,
  UtilityLinkProps,
} from './typings';

const defaultStyles: UtilityLinkFactoryOptionsStyles = {
  Link: '',
  Icon: '',
  Active: '',
};

const UtilityLinkFactory = ({
  SVGIcon,
  styles: appStyles,
  appAriaLabel = 'Link zu',
}: UtilityLinkFactoryOptions) => {
  const UtilityLink = (props: UtilityLinkProps): ReactElement<any> | null => {
    const {
      item,
      url,
      commentCount,
      isActive,
      origin,
      isRestricted,
      toastService,
      hideIconLabel = false,
    } = props;

    if (!item?.iconType) {
      return null;
    }
    let onClick: Record<string, (event?: MouseEvent) => void> | null = null;
    let path: Record<string, string> | null = null;
    const styles: UtilityLinkFactoryOptionsStyles =
      (typeof appStyles === 'function' && appStyles(props)) ||
      (typeof appStyles === 'object' && appStyles) ||
      defaultStyles;

    const restricted =
      isRestricted &&
      [
        UTILITY_TYPE_PRINT,
        UTILITY_TYPE_COMMENTS,
        UTILITY_TYPE_BOOKMARKS,
        UTILITY_TYPE_CONTACT,
        UTILITY_TYPE_CALCULATOR,
        UTILITY_TYPE_DOWNLOAD,
        UTILITY_TYPE_INFO,
      ].includes(item.id);

    if (item?.onClick) {
      onClick = {
        onClick: item.onClick,
      };
    }

    if (item?.url && !onClick) {
      path = {
        path: url,
      };
    }

    if (restricted && toastService) {
      path = null;
      onClick = {
        onClick: (event) => {
          event.preventDefault();
          toastService.displaySubscriptionOnlyInfoToast();
        },
      };
    }

    const trackingData = [
      {
        type: 'data-utility-button-type',
        value:
          (origin === UTILITY_BAR_ORIGIN_OVERLAY && 'share') || 'utility bar',
      },
      {
        type: 'data-utility-button-target',
        value: item.iconLabel,
      },
    ];

    return (
      <TestFragment data-testid="utility-link-wrapper">
        <Link
          target={item.targetType || '_self'}
          {...onClick}
          link={path}
          trackingData={trackingData}
          className={classNames('utility-button', styles.Link, {
            [styles.Active]: isActive,
            [styles.Restricted]: restricted,
            [item.addClass]: !!item.addClass,
          })}
          aria-label={`${appAriaLabel} ${item.iconLabel}`}
        >
          <>
            <SVGIcon type={item.iconType} className={styles.Icon} />
            {commentCount > 0 && (
              <span
                key={`comment-count-${commentCount}`}
                className={classNames(styles.Badge, {
                  [styles.Restricted]: restricted,
                })}
                data-testid="utility-link-comment-count-wrapper"
              >
                <span className={styles.CommentCount}>{commentCount}</span>
              </span>
            )}

            {!hideIconLabel && <p className={styles.Label}>{item.iconLabel}</p>}
          </>
        </Link>
      </TestFragment>
    );
  };

  return memo(UtilityLink);
};

export default UtilityLinkFactory;
