import React from 'react';
import createComponentSwitch from '../../../../../../../shared/decorators/componentSwitch';
import ProductTeaserBook from './components/ProductTeaserBook';
import ProductTeaserDefault from './components/ProductTeaserDefault';

export const PRODUCT_TYPE_DEFAULT = 'productDefault';
export const PRODUCT_TYPE_BOOK = 'productBook';

const Switch = createComponentSwitch({
  [PRODUCT_TYPE_DEFAULT]: ProductTeaserDefault,
  [PRODUCT_TYPE_BOOK]: ProductTeaserBook,
});

const ProductTeaser = (props) => <Switch {...props} />;
export default ProductTeaser;
