import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { useStableNavigate } from '../hooks/useStableNavigateContext';

export type WithNavigateProps = {
  navigate: NavigateFunction;
};

const withNavigate = (WrappedComponent) => {
  const WithNavigateHoC = (props) => {
    const navigate = useStableNavigate();
    return <WrappedComponent {...props} navigate={navigate}></WrappedComponent>;
  };

  return WithNavigateHoC;
};

export default withNavigate;
