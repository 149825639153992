/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { PUBLICATION_ID_DE, PUBLICATION_ID_FR } from '../../constants';
import { KEYWORD_PAGE_SIZE } from './constants';
import { GET_KEYWORDS_QUERY } from './queries';
import { RasRouterProps } from '../../components/Router/typings';

export const apolloConfig: RaschApolloConfig<RasRouterProps> = {
  options: ({
    location,
    params: { searchString = 'A' },
    props: { language },
  }) => {
    const page = location?.query?.page || 1;
    return {
      query: GET_KEYWORDS_QUERY,
      variables: {
        searchString: searchString.toUpperCase(),
        pageSize: KEYWORD_PAGE_SIZE,
        offset: (page - 1) * KEYWORD_PAGE_SIZE,
        publication: language === 'fr' ? PUBLICATION_ID_FR : PUBLICATION_ID_DE,
      },
      skip: !!(searchString && searchString.length > 1),
    };
  },
};
