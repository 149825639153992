import { TEASER_LAYOUT_BOOKMARKS } from '../../../../../shared/constants/teaser';
import { TYPE_TEASER } from '../constants';

export const teaserBookmark4x4 = {
  gridGroups: [
    {
      config: {
        hasContainer: true,
      },
      items: [
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BOOKMARKS,
        },
      ],
    },
  ],
};
