/* istanbul ignore file */

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import createComponentSwitch from '../../../shared/decorators/componentSwitch';
import { SVGIconFactoryOptions, SVGIconProps } from './typings';

export default ({
  iconConfig,
  type = 'type',
  styles,
}: SVGIconFactoryOptions) => {
  const Switch = createComponentSwitch(iconConfig, type);
  const SVGIcon = ({ ...props }: SVGIconProps): ReactElement => (
    <Switch
      {...props}
      className={classNames({
        [styles.Wrapper]: props.type.indexOf('svg-icons') > -1,
        [props.className]: !!props.className,
      })}
    />
  );

  return SVGIcon;
};
