import React from 'react';
import { Helmet } from 'react-helmet-async';
import createVideoObjectJsonLd, {
  VideoType,
} from '../../../shared/helpers/createVideoObjectJsonLd';

type createSSRHelmetType = (
  video: VideoType,
  imageUrl?: string,
  thumbnailUrl?: string,
) => JSX.Element;

export const createSSRHelmet: createSSRHelmetType = (
  video,
  imageUrl,
  thumbnailUrl,
) => {
  if (!video) return null;

  const jsonLd = createVideoObjectJsonLd(video, imageUrl, thumbnailUrl);
  const script: Array<Record<string, any>> = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(jsonLd),
    },
  ];
  return <Helmet script={script} />;
};
