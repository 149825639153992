import React from 'react';
import createComponentSwitch from '../../../../../../../../../shared/decorators/componentSwitch';
import DefaultProduct from './components/DefaultProduct';
import FullWidthImageLeftProduct from './components/FullWidthImageLeftProduct';

export const PRODUCT_STYLE_DEFAULT = 'default';
export const PRODUCT_STYLE_FULL_WIDTH_IMAGE_LEFT = 'fullWidthImageLeft';

const Switch = createComponentSwitch(
  {
    [PRODUCT_STYLE_DEFAULT]: DefaultProduct,
    [PRODUCT_STYLE_FULL_WIDTH_IMAGE_LEFT]: FullWidthImageLeftProduct,
  },
  'type',
);

const ProductTeaser = (props) => <Switch {...props} />;
export default ProductTeaser;
