import {
  DEVICE_TYPE_ANDROID,
  DEVICE_TYPE_IOS_MOBILE_TABLET,
  getMobileOperatingSystem,
  log,
} from '../../../shared/helpers/utils';

export const SPONSORED_CONTENT_PREFIX = '[Sponsored]';

export const convertUrl = ({
  url,
  shareUrl = '',
  title,
  shortTitle,
  lead = '',
  imageUrl = '',
  additionalQueryParam = '',
  socialMediaTitle,
  hasSponsoredContentPrefix = false,
  isHybridApp = false,
}): string => {
  const hasQueryParam =
    shareUrl.indexOf('?') !== -1 || shareUrl.indexOf('%3F') !== -1;
  const paramDelimiter = hasQueryParam && additionalQueryParam ? '&' : '?';

  const routeSearch = __CLIENT__ && global.location?.search.replace('?', '');
  const routeHash = __CLIENT__ && global.location.hash;
  const locationOrigin = isHybridApp
    ? global.locationOrigin.replace('//app.', '//www.')
    : global.locationOrigin;

  if (routeSearch) {
    additionalQueryParam = `${routeSearch}${
      additionalQueryParam && '&'
    }${additionalQueryParam}`;
  }
  let finalUrl =
    (additionalQueryParam &&
      `${shareUrl}${paramDelimiter}${additionalQueryParam}`) ||
    shareUrl;

  if (routeHash) {
    finalUrl = `${finalUrl}#${routeHash}`;
  }
  const socialTitle = socialMediaTitle || title;
  const prefixedSocialMediaTitle = `${SPONSORED_CONTENT_PREFIX} ${socialTitle}`;
  let ogImage = imageUrl || '';
  if (!ogImage && url.indexOf('[field_og_image]') !== -1) {
    ogImage = global.socialMetaValues?.field_heroimage || '';
    log('convertUrl', `setting to: ${ogImage}`, 'green');
  }

  return url
    .replace('[url]', encodeURIComponent(`${locationOrigin}${finalUrl}`))
    .replace('[field_title]', encodeURIComponent(title))
    .replace('[field_lead]', encodeURIComponent(lead))
    .replace('[field_short_title]', encodeURIComponent(shortTitle))
    .replace('[field_og_image]', ogImage)
    .replace(
      '[field_social_media_title]',
      encodeURIComponent(
        (hasSponsoredContentPrefix && prefixedSocialMediaTitle) || socialTitle,
      ),
    );
};

export const openWebShareAPIDialog = ({
  event,
  url,
  title = '',
  lead = '',
  isHybridApp = false,
  fallback,
}) => {
  event.preventDefault();
  const isMobileDevice = getMobileOperatingSystem();

  if (
    !global.navigator?.share ||
    (!isMobileDevice &&
      isMobileDevice !== DEVICE_TYPE_ANDROID &&
      isMobileDevice !== DEVICE_TYPE_IOS_MOBILE_TABLET)
  ) {
    fallback();
    return;
  }

  if (isHybridApp) {
    url = url.replace('//app.', '//www.');
  }

  global.navigator
    .share({
      title,
      text: lead,
      url,
    })
    .then(() => {
      return;
    })
    .catch((error) => {
      if (error.name === 'NotAllowedError') {
        fallback();
      }
      return;
    });
};
