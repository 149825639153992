import { ComponentType, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultOptions, scrollToAnchorElement } from '../SmoothScroll/helpers';
import locationStateSelector from '../../../shared/selectors/locationStateSelector';
import { setDeviceId } from '../../../shared/actions/auth';
import { setNavigationVisible } from '../../../shared/actions/navigation';
import { setExternalSubscription } from '../../../shared/actions/piano';
import { Auth0 } from '../Auth0Provider';
import { TYPE_NAVIGATION_MENU_USER } from './constants';
import { useStableNavigate } from '../../../shared/hooks/useStableNavigateContext';
const addedListeners = {};
const HYBRID_APP_EVENT_PREFIX = 'RASCH-HYBRID-APP';
export const WEB_APP_EVENT_NAMESPACE = 'WEB';
const NATIVE_APP_EVENT_NAMESPACE = 'NATIVE';

export const getEventName = (name: string, namespace: string): string =>
  `${HYBRID_APP_EVENT_PREFIX}-${namespace}-${name}`;

export const dispatchHybridAppEvent = (name: string, detail: any): void => {
  if (typeof CustomEvent === 'undefined') {
    return;
  }
  const event = new CustomEvent(
    getEventName(name, NATIVE_APP_EVENT_NAMESPACE),
    {
      detail,
    },
  );

  global.dispatchEvent(event);
};

export const addWebAppEventListener = (
  name: string,
  handler: (event: CustomEvent) => void,
): void => {
  const extendendName = getEventName(name, WEB_APP_EVENT_NAMESPACE);
  if (addedListeners[extendendName]) return;
  addedListeners[extendendName] = handler;

  window.addEventListener(extendendName, handler);
};

export const removeWebAppEventListener = (
  name: string,
  handler: (event: CustomEvent) => void,
): void => {
  delete addedListeners[getEventName(name, WEB_APP_EVENT_NAMESPACE)];
  window.removeEventListener(
    getEventName(name, WEB_APP_EVENT_NAMESPACE),
    handler,
  );
};

type HybridAppEventConfig = {
  name: string;
  handler: (event: CustomEvent) => void;
};

const HybridAppProvider: ComponentType = () => {
  const navigate = useStableNavigate();
  const isHybridApp = useSelector(
    (state) => locationStateSelector(state)?.isHybridApp || false,
  );
  const dispatch = useDispatch();

  const nativeEvents = useMemo<Array<HybridAppEventConfig>>(
    () => [
      {
        name: getEventName('navigate', WEB_APP_EVENT_NAMESPACE),
        handler: (event) => {
          if (event?.detail?.href) {
            navigate(event.detail.href);
          }
        },
      },
      {
        name: getEventName('menu-user-open', WEB_APP_EVENT_NAMESPACE),
        handler: () => {
          dispatch(setNavigationVisible(TYPE_NAVIGATION_MENU_USER));
        },
      },
      {
        name: getEventName('menu-user-close', WEB_APP_EVENT_NAMESPACE),
        handler: () => {
          dispatch(setNavigationVisible(null));
        },
      },
      {
        name: getEventName('handle-comments-click', WEB_APP_EVENT_NAMESPACE),
        handler: () => {
          scrollToAnchorElement('comments', {
            ...defaultOptions,
            replace: true,
          });
        },
      },
      {
        name: getEventName(
          'register-onesignal-player-id',
          WEB_APP_EVENT_NAMESPACE,
        ),
        handler: (event) => {
          dispatch(setDeviceId(event?.detail?.playerId));
        },
      },
      {
        name: getEventName('register-iap', WEB_APP_EVENT_NAMESPACE),
        handler: () => {
          const externalSubsription = 'in-app';
          dispatch(setExternalSubscription(externalSubsription));
          Auth0.setExternalSubscription(externalSubsription);
        },
      },
      {
        name: getEventName('one-trust-allow', WEB_APP_EVENT_NAMESPACE),
        handler: (event) => {
          if (event?.detail?.enable) {
            global?.OneTrust?.AllowAll();
          }
        },
      },
      {
        name: getEventName('one-trust-disallow', WEB_APP_EVENT_NAMESPACE),
        handler: (event) => {
          if (event?.detail?.enable) {
            global?.OneTrust?.RejectAll();
          }
        },
      },
      {
        name: getEventName('one-trust-info-display', WEB_APP_EVENT_NAMESPACE),
        handler: () => {
          global?.OneTrust?.ToggleInfoDisplay();
        },
      },
    ],
    [dispatch, navigate],
  );

  useEffect(() => {
    if (isHybridApp) {
      nativeEvents.forEach((event) => {
        window.addEventListener(event.name, event.handler);
      });
    }

    return () => {
      nativeEvents.forEach((event) => {
        window.removeEventListener(event.name, event.handler);
      });
    };
  }, [isHybridApp, nativeEvents]);

  return null;
};

export default HybridAppProvider;
