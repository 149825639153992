/**
 * @file   global search graphql constants
 */

export const GLOBAL_SEARCH_FILTER_PAGE = 'Page';
export const GLOBAL_SEARCH_FILTER_ARTICLE = 'Article';
export const GLOBAL_SEARCH_FILTER_EXPLAINING_ARTICLE = 'ExplainingArticle';
export const GLOBAL_SEARCH_FILTER_LANDING_PAGE = 'LandingPage';
export const GLOBAL_SEARCH_FILTER_ORGANIZATION = 'Organization';
export const GLOBAL_SEARCH_FILTER_PERSON = 'Person';
export const GLOBAL_SEARCH_FILTER_PRODUCT = 'Product';
export const GLOBAL_SEARCH_FILTER_RECIPE = 'Recipe';
export const GLOBAL_SEARCH_FILTER_SPONSOR = 'Sponsor';
export const GLOBAL_SEARCH_FILTER_KEYWORD = 'Keyword';

export const GLOBAL_SEARCH_SORT_BY_RELEVANCE = 'Relevance';
export const GLOBAL_SEARCH_SORT_BY_DATE = 'ModificationDate';
export const GLOBAL_SEARCH_SORT_BY_DATE_DATE = 'Date';
export const GLOBAL_SEARCH_SORT_BY_RANDOM = 'Random';
export const GLOBAL_SEARCH_SORT_BY_CHANGED = 'Changed';
export const GLOBAL_SEARCH_SORT_BY_POINTS = 'Points';
export const GLOBAL_SEARCH_SORT_BY_MODIFICATION_DATE = 'UpdatedAt';

export const GLOBAL_SEARCH_SORT_ASC = 'Ascending';
export const GLOBAL_SEARCH_SORT_DESC = 'Descending';
