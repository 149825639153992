// All common teaser layout types should be stored here in case we want to use them for gloabl teaser grid layouts
export const TEASER_LAYOUT_BLOG_POST_MAXI = 'teaser/blog-post-maxi';
export const TEASER_LAYOUT_BLOG_POST_MEDIUM = 'teaser/blog-post-medium';
export const TEASER_LAYOUT_BOOKMARKS = 'teaser/layout-bookmark';
export const TEASER_LAYOUT_CHANNEL = 'teaser/layout-channel';
export const TEASER_LAYOUT_HERO = 'teaser/layout-hero';
export const TEASER_LAYOUT_HERO_A = 'teaser/layout-hero-a';
export const TEASER_LAYOUT_HERO_B = 'teaser/layout-hero-a-right-aligned';
export const TEASER_LAYOUT_HERO_MAIN = `teaser/layout-hero-main`;
export const TEASER_LAYOUT_HERO_S = 'teaser/hero-s';
export const TEASER_LAYOUT_HERO_XL = 'teaser/hero-xl';
export const TEASER_LAYOUT_HERO_XL_2 = 'teaser/hero-xl-2';
export const TEASER_LAYOUT_HERO_XL_2_X_1 = 'teaser/hero-xl-2x1';
export const TEASER_LAYOUT_HERO_XL_SLIDER = 'teaser/hero-xl-slider';
export const TEASER_LAYOUT_HORIZONTAL = 'teaser/layout-horizontal';
export const TEASER_LAYOUT_HOROSCOPE = 'teaser/horoscope';
export const TEASER_LAYOUT_INLINE_RECIPE = 'teaser/layout-inline-teaser-recipe';
export const TEASER_LAYOUT_IS_SPECIAL = 'special';
export const TEASER_LAYOUT_L = 'teaser/l';
export const TEASER_LAYOUT_LANDINGPAGE = 'teaser/layout-landingpage';
export const TEASER_LAYOUT_LANDING_PAGES_STATIC = 'teaser/landing-pages-static';
export const TEASER_LAYOUT_LATEST = 'teaser/latest';
export const TEASER_LAYOUT_M = 'teaser/m';
export const TEASER_LAYOUT_MD = 'teaser/md';
export const TEASER_LAYOUT_MD_COLUMN = 'teaser/md-column';
export const TEASER_LAYOUT_ML = 'teaser/layout-ml';
export const TEASER_LAYOUT_ORGANIZATION = 'teaser/layout-organization';
export const TEASER_LAYOUT_PERSON = 'teaser/layout-person';
export const TEASER_LAYOUT_POP_STAGE = 'teaser/pop-stage';
export const TEASER_LAYOUT_PORTRAIT = 'teaser/portrait';
export const TEASER_LAYOUT_PRODUCT = 'teaser/product';
export const TEASER_LAYOUT_RANKING = 'teaser/layout-ranking';
export const TEASER_LAYOUT_RANKING_LIST = 'teaser/layout-ranking-list';
export const TEASER_LAYOUT_RECOMMENDATIONS = 'teaser/layout-recommendations';
export const TEASER_LAYOUT_RELATED = 'teaser/related';
export const TEASER_LAYOUT_S = 'teaser/s';
export const TEASER_LAYOUT_SM = 'teaser/sm';
export const TEASER_LAYOUT_SHOP_PRODUCT = 'teaser/layout-shop-product';
export const TEASER_LAYOUT_SHORT_TITLE = 'teaser-short-title';
export const TEASER_LAYOUT_SPECIAL = 'teaser/layout-special';
export const TEASER_LAYOUT_SPECIAL_HERO = 'teaser/special-hero';
export const TEASER_LAYOUT_SPECIAL_M = 'teaser/special-m';
export const TEASER_LAYOUT_SPECIAL_S = 'teaser/special-s';
export const TEASER_LAYOUT_SPONSOR = 'teaser/layout-sponsor';
export const TEASER_LAYOUT_SPONSOR_LIST = 'teaser/layout-sponsor-list';
export const TEASER_LAYOUT_SUBSCRIPTION_L = 'teaser/subscription-l';
export const TEASER_LAYOUT_SUBSCRIPTION_M = 'teaser/subscription-m';
export const TEASER_LAYOUT_TEAM_MEMBERS = 'teaser/layout-team-members';
export const TEASER_LAYOUT_TEXT = 'teaser/layout-text';
export const TEASER_LAYOUT_TEXT_CONTENT_BOX = 'teaser/layout-text-content-box';
export const TEASER_LAYOUT_TIMELINE = 'teaser/layout-timeline';
export const TEASER_LAYOUT_TRADING = 'teaser/layout-trading';
export const TEASER_LAYOUT_VIDEO = 'teaser/layout-video';
export const TEASER_LAYOUT_VIDEO_S = 'teaser/video-s';
export const TEASER_LAYOUT_XL = 'teaser/xl';
export const TEASER_LAYOUT_XS = 'teaser/xs';
export const TEASER_LAYOUT_XXS = 'teaser/xxs';
export const TEASER_LAYOUT_PUZZLE = 'teaser/puzzle';

export const TEASER_LAYOUT_HERO_NEW = 'teaser/layout-hero-new';
export const TEASER_LAYOUT_WIDE = 'teaser/layout-wide';
export const TEASER_LAYOUT_LEGAL_ADVICE = 'teaser/layout-legal-advice';
export const TEASER_LAYOUT_AUTHOR = 'teaser/author';
export const TEASER_LAYOUT_AUTHOR_SM = 'teaser/author-sm';
export const TEASER_LAYOUT_TOOL = 'teaser/tool';
