export const PAGE_SIZE = 10;

export const DE_RECIPE_FILTER_LIST = [
  {
    label: 'Alle Rezepte',
    url: '/rezepte',
  },
  {
    label: 'Fleisch',
    url: '/rezepte/kategorie/fleisch',
  },
  {
    label: 'Fisch',
    url: '/rezepte/kategorie/fisch',
  },
  {
    label: 'Pasta & Risotto',
    url: '/rezepte/kategorie/pasta-und-risotto',
  },
  {
    label: 'Suppen',
    url: '/rezepte/kategorie/suppen',
  },
  {
    label: 'Vegi & vegan',
    url: '/rezepte/kategorie/vegi-und-vegan',
  },
  {
    label: 'Spargeln',
    url: '/rezepte/kategorie/spargeln',
  },
  {
    label: 'Dessert',
    url: '/rezepte/kategorie/dessert',
  },
  {
    label: 'Brot',
    url: '/rezepte/kategorie/brot',
  },
  {
    label: 'Drinks',
    url: '/rezepte/kategorie/drinks',
  },
  {
    label: 'Weihnachtsguetzli',
    url: '/rezepte/kategorie/weihnachtsguetzli',
  },
];

export const FR_RECIPE_FILTER_LIST = [
  {
    label: 'Toutes les recettes',
    url: '/fr/recettes',
  },
  {
    label: 'Viande',
    url: '/fr/recettes/categorie/viande',
  },
  {
    label: 'Poisson',
    url: '/fr/recettes/categorie/poisson',
  },
  {
    label: 'Pâtes et risotto',
    url: '/fr/recettes/categorie/pates-et-risotto',
  },
  {
    label: 'Vegan & végétarien',
    url: '/fr/recettes/categorie/vegan-et-vegetarien',
  },
  {
    label: 'Asperges',
    url: '/fr/recettes/categorie/asperges',
  },
  {
    label: 'Desserts',
    url: '/fr/recettes/categorie/desserts',
  },
  {
    label: 'Pain',
    url: '/fr/recettes/categorie/pain',
  },
  {
    label: 'Boissons',
    url: '/fr/recettes/categorie/boissons',
  },
  {
    label: 'Biscuits de Noël',
    url: '/fr/recettes/categorie/biscuits-de-noel',
  },
];
