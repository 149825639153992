import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { noop } from '../../../../../../../shared/helpers/utils';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import Link from '../../../../../Link';
import {
  EmbedConsentBlockFactoryOptions,
  EmbedConsentBlockFactoryOptionsStyles,
  EmbedConsentBlockProps,
} from './typings';

type EmbedConsentBlockPropsInner = EmbedConsentBlockProps;
const defaultStyles = {
  Wrapper: '',
  Title: '',
  Link: '',
  Lead: '',
  Container: '',
  LinkWrapper: '',
};

const EmbedConsentBlockFactory = ({
  styles: appStyles,
  Button: ButtonWithLoading = noop,
  consentBlockContent,
}: EmbedConsentBlockFactoryOptions) => {
  const EmbedConsentBlock = (
    props: EmbedConsentBlockPropsInner,
  ): ReactElement => {
    const styles: EmbedConsentBlockFactoryOptionsStyles =
      (typeof appStyles === 'function' && appStyles(props)) ||
      (typeof appStyles === 'object' && appStyles) ||
      defaultStyles;

    const toggleConsentInfoDisplay = () => {
      if (window.toggleConsentInfoDisplay) {
        window.toggleConsentInfoDisplay();
      }
    };

    const setConsentForAll = () => {
      if (typeof window.setOneTrustConsentForAll === 'function') {
        window.setOneTrustConsentForAll();
      }
    };

    const button = (
      <ButtonWithLoading clickHandler={setConsentForAll} {...props} />
    );

    // Language switch
    const consentBlockData =
      (props.language && consentBlockContent[props.language]) ||
      consentBlockContent;

    return (
      <div
        data-testid="embed-consent-block-wrapper"
        className={classNames(styles.Wrapper, grid.HideForPrint)}
      >
        <div className={styles.Container}>
          <div className={styles.Title}>{consentBlockData.title}</div>
          <p className={styles.Lead}>{consentBlockData.leadContent}</p>
          <div>
            {button}
            <div className={styles.LinkWrapper}>
              <Link
                className={styles.Link}
                label={consentBlockData.linkLabel}
                onClick={toggleConsentInfoDisplay}
              ></Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return EmbedConsentBlock;
};

export default EmbedConsentBlockFactory;
