import React from 'react';
import usePianoTrackingData from '../hooks/usePianoTrackingData';

const withPianoTrackingData = (Component) => {
  const WithPianoTrackingDataHOC = (props) => {
    usePianoTrackingData(props);

    return <Component {...props} />;
  };
  return WithPianoTrackingDataHOC;
};

export default withPianoTrackingData;
