import React, { useCallback, useEffect, useRef, useState } from 'react';
import mod from 'react-swipeable-views-core/lib/mod';

// based on https://github.com/oliviertassinari/react-swipeable-views/blob/master/packages/react-swipeable-views-utils/src/autoPlay.js
// but created as function component with hoooks and with functionality of stopping autoplay on hover
const Autoplay = (BaseComponent) => {
  const Component = ({
    autoplay = true,
    interval = 5000,
    onChangeIndex,
    index: indexProp,
    ...props
  }) => {
    const [index, setIndex] = useState(indexProp || 0);
    const intervalIdRef = useRef(null);
    const timer = useRef(null);
    const initialTime = useRef(null);

    useEffect(() => {
      if (indexProp !== index) {
        setIndex(indexProp);
      }
    }, [indexProp, setIndex, index]);

    const handleInterval = useCallback(() => {
      const { children, slideCount } = props;
      const indexLatest = indexProp;
      let indexNew = indexLatest;

      indexNew += 1;

      if (slideCount || children) {
        indexNew = mod(indexNew, slideCount || React.Children.count(children));
      }

      // Is uncontrolled
      if (props.index === undefined) {
        setIndex(indexNew);
      }

      if (onChangeIndex) {
        onChangeIndex(indexNew, indexLatest, { isAutoplayed: true });
      }
    }, [props, onChangeIndex, indexProp]);

    const handleChangeIndex = (index, indexLatest, meta) => {
      // Is uncontrolled
      if (props.index === undefined) {
        setIndex(index);
      }

      if (onChangeIndex) {
        onChangeIndex(index, indexLatest, meta);
      }
    };

    const handleSwitching = (index, type) => {
      timer.current = null;
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      } else if (type === 'end') {
        startInterval();
      }

      if (props.onSwitching) {
        props.onSwitching(index, type);
      }
    };

    const startInterval = useCallback(() => {
      clearInterval(intervalIdRef.current);

      if (autoplay) {
        initialTime.current = Date.now();
        intervalIdRef.current = setInterval(
          handleInterval,
          timer.current ? interval - timer.current : interval,
        );
      }
    }, [handleInterval, interval, autoplay]);

    const handleMouseLeave = () => {
      if (autoplay) {
        startInterval();
      }
    };

    const handleMouseEnter = () => {
      if (autoplay) {
        timer.current = timer.current + (Date.now() - initialTime.current);
        clearInterval(intervalIdRef.current);
      }
    };

    useEffect(() => {
      if (autoplay) {
        startInterval();
        return () => {
          timer.current = null;
          initialTime.current = null;
          clearInterval(intervalIdRef.current);
        };
      }
    }, [autoplay, startInterval, interval]);

    return (
      <BaseComponent
        index={index}
        onChangeIndex={handleChangeIndex}
        onSwitching={handleSwitching}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      />
    );
  };

  return Component;
};

export default Autoplay;
