export const UTILITY_BAR_THEME_LIGHT = 'light';
export const UTILITY_BAR_ORIGIN_OVERLAY = 'overlay';
export const UTILITY_BAR_ORIGIN_INLINE_OVERLAY = 'inline-overlay';

// UtilityBar ids
export const UTILITY_TYPE_WHATSAPP = 'utility-bar/whatsapp';
export const UTILITY_TYPE_WHATSAPP_DESKTOP = 'utility-bar/whatsapp-desktop';
export const UTILITY_TYPE_FACEBOOK = 'utility-bar/facebook';
export const UTILITY_TYPE_MESSENGER = 'utility-bar/messenger';
export const UTILITY_TYPE_LINKEDIN = 'utility-bar/linkedIn';
export const UTILITY_TYPE_XING = 'utility-bar/xing';
export const UTILITY_TYPE_GETPOCKET = 'utility-bar/getPocket';
export const UTILITY_TYPE_PINTEREST = 'utility-bar/pinterest';
export const UTILITY_TYPE_TWITTER = 'utility-bar/twitter';
export const UTILITY_TYPE_EMAIL = 'utility-bar/mail';
export const UTILITY_TYPE_PRINT = 'utility-bar/print';
export const UTILITY_TYPE_SHARE = 'utility-bar/share';
export const UTILITY_TYPE_COMMENTS = 'utility-bar/comments';
export const UTILITY_TYPE_BOOKMARKS = 'utility-bar/bookmarks';
export const UTILITY_TYPE_CONTACT = 'utility-bar/contact';
export const UTILITY_TYPE_CALCULATOR = 'utility-bar/calculator';
export const UTILITY_TYPE_DOWNLOAD = 'utility-bar/download';
export const UTILITY_TYPE_INFO = 'utility-bar/info';
export const UTILITY_TYPE_GOOGLE_NEWS = 'utility-bar/google-news';
export const UTILITY_TYPE_GOOGLE_NEWS_BIL = 'utility-bar/google-news-bil';

// UtilityBar labels
export const UTILITY_BAR_WHATSAPP_LABEL = 'Whatsapp';
export const UTILITY_BAR_FACEBOOK_LABEL = 'Facebook';
export const UTILITY_BAR_MESSENGER_LABEL = 'Messenger';
export const UTILITY_BAR_LINKEDIN_LABEL = 'LinkedIn';
export const UTILITY_BAR_XING_LABEL = 'Xing';
export const UTILITY_BAR_GETPOCKET_LABEL = 'GetPocket';
export const UTILITY_BAR_PINTEREST_LABEL = 'Pinterest';
export const UTILITY_BAR_TWITTER_LABEL = 'X';
export const UTILITY_BAR_EMAIL_LABEL = 'Mail';
export const UTILITY_BAR_PRINT_LABEL = 'Drucken';
export const UTILITY_BAR_PRINT_LABEL_FR = 'Imprimer';
export const UTILITY_BAR_SHARE_LABEL = 'Teilen';
export const UTILITY_BAR_SHARE_LABEL_FR = 'Partager';
export const UTILITY_BAR_COMMENTS_LABEL = 'Kommentare';
export const UTILITY_BAR_BOOKMARKS_INACTIVE_LABEL = 'Merken';
export const UTILITY_BAR_BOOKMARKS_ACTIVE_LABEL = 'Entfernen';
export const UTILITY_BAR_BOOKMARKS_INACTIVE_LABEL_FR = 'Conserver';
export const UTILITY_BAR_BOOKMARKS_ACTIVE_LABEL_FR = 'Supprimer';
export const UTILITY_BAR_CONTACT_LABEL = 'Kontakt';
export const UTILITY_BAR_CALCULATOR_LABEL = 'Rechner';
export const UTILITY_BAR_DOWNLOAD_LABEL = 'Download';
export const UTILITY_BAR_INFO_LABEL = 'FAQ/Hilfe';
export const UTILITY_BAR_GOOGLE_NEWS_LABEL = 'Google News';
