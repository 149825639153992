/**
 * @file   paragraphs constants
 */

export const AD_PARAGRAPH = 'ad';
export const BLOCKQUOTE_PARAGRAPH = 'BlockquoteParagraph';
export const CHANNEL_BOX_PARAGRAPH = 'ChannelBoxParagraph';
export const EMBED_PARAGRAPH = 'EmbedParagraph';
export const ENTITY_QUEUE_PARAGRAPH = 'EntityQueueParagraph';
export const FOCUS_BOX_PARAGRAPH = 'FocusBoxParagraph';
export const IMAGE_PARAGRAPH = 'ImageParagraph';
export const CONTENT_PARAGRAPH = 'ContentParagraph';
export const IMAGE_GALLERY_PARAGRAPH = 'ImageGalleryParagraph';
export const INFOBOX_PARAGRAPH = 'InfoBoxParagraph';
export const INPUT_FORM_PARAGRAPH = 'InputFormParagraph';
export const BOOKING_FORM_PARAGRAPH = 'bookingform';
export const LINK_BOX_PARAGRAPH = 'LinkBoxParagraph';
export const MINISTAGE_PARAGRAPH = 'MinistageParagraph';
export const HERO_MEDIA_PARAGRAPH = 'HeroMediaParagraph';
export const MULTI_COLUMNS_PARAGRAPH = 'MultiColumnParagraph';
export const WIDGET_PARAGRAPH = 'WidgetParagraph';
export const NATIVE_ADVERTISING_CAROUSEL_PARAGRAPH =
  'NativeAdvertisingCarouselParagraph';
export const TEASER_PARAGRAPH = 'TeaserParagraph';
export const TEASER_STAGE_PARAGRAPH = 'TeaserStageParagraph';
export const TEXT_PARAGRAPH = 'TextParagraph';
export const VIDEO_LOOP_PARAGRAPH = 'VideoLoopParagraph';
export const VIDEO_PARAGRAPH = 'VideoParagraph';
export const PARALLAX_IMAGE_PARAGRAPH = 'ParallaxImageParagraph';
export const SECTION_PARAGRAPH = 'SectionParagraph';
export const CONTENT_STAGE_PARAGRAPH = 'ContentStageParagraph';
export const LISTICLE_ITEM_PARAGRAPH = 'ListicleItemParagraph';
export const RANKING_LIST_PARAGRAPH = 'RankingListParagraph';
export const DISRUPTOR_PARAGRAPH = 'DisruptorParagraph';
export const PIANO_TEMPLATE_PARAGRAPH = 'PianoTemplateParagraph';
export const ADVANTAGES_PARAGRAPH = 'AdvantagesParagraph';
export const PLACEHOLDER_PARAGRAPH = 'PlaceholderParagraph';

export const MINISTAGE_COMPONENT_VIDEO = 'MinistageVideo';
export const MINISTAGE_CHANNEL_SPONSOR = 'MinistageChannelSponsor';
export const MINISTAGE_TRENDING_TOPICS = 'MinistageTrendingTopics';
export const MINISTAGE_SINGLE_ALERT_TOPIC = 'MinistageSingleAlertTopic';
export const MINISTAGE_COMPONENT_ACCORDION = 'MinistageAccordion';
export const MINISTAGE_COMPONENT_LISTICLE = 'MinistageListicle';
export const MINISTAGE_COMPONENT_GUIDER = 'MinistageGuider';
export const MINISTAGE_COMPONENT_NEWSLETTER = 'MinistageNewsletter';
export const MINISTAGE_COMPONENT_TEASER = 'MinistageTeaser';
export const MINISTAGE_COMPONENT_TRENDING_TOPICS = 'MinistageTrendingTopics';
export const MINISTAGE_COMPONENT_SINGLE_ALERT_TOPIC =
  'MinistageSingleAlertTopic';
export const MINISTAGE_COMPONENT_DISRUPTOR = 'MinistageDisruptor';
export const MINISTAGE_COMPONENT_SOCIAL_MEDIA = 'MinistageSocialMedia';
export const MINISTAGE_COMPONENT_LOGO_BOX = 'MinistageLogoBox';
export const MINISTAGE_HOROSCOPES = 'MinistageHoroscopes';
export const MINISTAGE_AUTHOR = 'MinistageAuthor';
export const MINISTAGE_SEARCH = 'MinistageSearch';

// Paragraphs styles
export const TEASER_STAGE_PARAGRAPH_STYLE_RANDOMIZED_LIST = 'randomized_list';
export const TEASER_STAGE_PARAGRAPH_STYLE_HEADLESS = 'headless';

export const TEXT_PARAGRAPH_INFOBOX_STYLE_VALUE = 'info_box';
export const TEXT_PARAGRAPH_TABLE_STYLE_VALUE = 'table';
export const TEXT_PARAGRAPH_TABLE_WITHOUT_BORDERS_STYLE_VALUE =
  'table_without_borders';
export const TEXT_PARAGRAPH_TABLE_STRIPED_STYLE_VALUE = 'table_striped';

// This paragraph is not (yet) availabe in the CMS. It is used for the semi-static landing page for Musterportfolio (/trading-ideen)
export const PORTFOLIO_SUMMARY_PARAGRAPH = 'PortfolioSummary';
export const PORTFOLIO_PARAGRAPH = 'Portfolio';
export const PORTFOLIO_TRANSACTIONS_BUY_PARAGRAPH = 'PortfolioTransactionsBuy';
export const PORTFOLIO_TRANSACTIONS_SELL_PARAGRAPH =
  'PortfolioTransactionsSell';
