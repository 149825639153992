export const TYPE_TEASER = 'teaser-grid/teaser';
export const TYPE_AD = 'teaser-grid/ad-zone';
export const TYPE_RIGHT_WIDGET = 'teaser-grid/right-widget';
export const TYPE_ESI_WIDGET_PARAGRAPH = 'teaser-grid/widget-paragraph';
export const TYPE_STATIC = 'teaser-grid/static-alternative-teaser';

export const GRID_LAYOUT_TEASER_S_4X4 = 'teaserS4x4';
export const GRID_LAYOUT_TEASER_STAGE_1_ITEM = 'teaserStage1Item';
export const GRID_LAYOUT_TEASER_STAGE_2_ITEMS = 'teaserStage2Items';
export const GRID_LAYOUT_TEASER_STAGE_3_ITEMS = 'teaserStage3Items';
export const GRID_LAYOUT_TEASER_STAGE_DEFAULT = 'teaserStageDefault';
export const GRID_LAYOUT_TEASER_STAGE_RANDOMIZED_LIST =
  'teaserStageRandomizedList';
export const GRID_LAYOUT_TEASER_STAGE_UNLIMITED = 'teaserStageUnlimited';
export const GRID_LAYOUT_TEASER_BOOKMARK_4X4 = 'teaserBookmark4x4';
export const GRID_LAYOUT_PORTRAIT = 'portrait';
export const GRID_LAYOUT_LANDSCAPE = 'landscape';
export const GRID_LAYOUT_MIXED = 'mixed';
