export const DEFAULT_PUBLICATION = 'GM';
const DEFAULT_PUBLICATION_TITLE = 'GaultMillau';
export const SOCIAL_MEDIA_LINK_FACEBOOK =
  'https://www.facebook.com/gaultmillau.schweiz/';
export const SOCIAL_MEDIA_LINK_GOOGLE_NEWS =
  'https://news.google.com/publications/CAAqBwgKMI2TpQsw0J29Aw?hl=de&gl=CH&ceid=CH%3Ade';
export const BRIGHTCOVE_ACCOUNT_ID = '2112711546001';
export const BRIGHTCOVE_PLAYER_ID = 'HJSbdPW2x';

export const MAIN_CONTENT_ID = 'main';
export const PUBLICATION_ID_DE = 'GM';
export const PUBLICATION_ID_FR = 'GM_FR';

export const ARTICLE_TYPE_BLOG_A = 'blog_a';
export const ARTICLE_TYPE_BLOG_B = 'blog_b';
export const ARTICLE_TYPE_BLOG_C = 'blog_c';
export const ARTICLE_TYPE_BLOG_D = 'blog_d';
export const ARTICLE_TYPE_BLOG_E = 'blog_e';
export const ARTICLE_TYPE_BLOG_F = 'blog_f';
export const ARTICLE_TYPE_BLOG_G = 'blog_g';
export const ARTICLE_TYPE_BLOG_H = 'blog_h';
export const ARTICLE_TYPE_BLOG_I = 'blog_i';
export const ARTICLE_TYPE_BLOG_J = 'blog_j';
export const ARTICLE_TYPE_BLOG_K = 'blog_k';
export const ARTICLE_TYPE_BLOG_L = 'blog_l';
export const ARTICLE_TYPE_BLOG_M = 'blog_m';
export const ARTICLE_TYPE_BLOG_N = 'blog_n';
export const OVERRULE_POSTFIX_FALLBACK_HANDLING = 'blog_no_prefix';
export const BLOG_DATA = {
  blog_a_de: {
    title: 'Züri isst',
    url: 'zueri-isst',
  },
  blog_a_fr: {
    title: 'Romandie gourmande',
    url: 'fr/romandie-gourmande',
  },
  blog_b_de: {
    title: 'Atelier Caminada',
    url: 'atelier-caminada',
    postfix: 'videos & more',
  },
  blog_b_fr: {
    title: 'Le studio des chefs',
    url: 'fr/le-studio-des-chefs',
    postfix: 'Vidéo',
  },
  blog_c: {
    title: 'Master of wine',
  },
  blog_d: {
    title: 'Uncorked',
    postfix: 'Edvin bloggt',
  },
  blog_e: {
    title: 'Bern isst',
  },
  blog_f: {
    title: 'Basel isst',
  },
  blog_g: {
    title: DEFAULT_PUBLICATION_TITLE,
    postfix: 'Böniger tranchiert',
  },
  blog_h: {
    title: 'Noah kocht',
    postfix: 'Das Video',
  },
  blog_i: {
    title: DEFAULT_PUBLICATION_TITLE,
    postfix: 'Heller checkt ein',
  },
  blog_j: {
    title: DEFAULT_PUBLICATION_TITLE,
    postfix: 'Patricia fragt nach',
  },
  blog_k: {
    title: DEFAULT_PUBLICATION_TITLE,
    postfix: 'Schnapp kocht mit',
  },
  blog_l: {
    title: 'Der GAULTMILLAU-Hoteltest',
    postfix: OVERRULE_POSTFIX_FALLBACK_HANDLING,
  },
  blog_m: {
    title: DEFAULT_PUBLICATION_TITLE,
    postfix: 'Cruise-Test',
  },
  blog_n: {
    title: DEFAULT_PUBLICATION_TITLE,
    postfix: 'Airline-Test',
  },
};

export const URL_DE_MAP = 'map';
export const URL_FR_MAP = 'fr/map';
export const URL_DE_RESTAURANTS = 'restaurants';
export const URL_DE_POP_RESTAURANTS = 'pop';
export const URL_FR_POP_RESTAURANTS = 'fr/pop';
export const URL_FR_RESTAURANTS = 'fr/restaurants';
export const URL_DE_RESTAURANTS_SEARCH = '/restaurants-suche';
export const URL_FR_RESTAURANTS_SEARCH = '/fr/restaurant-search';
export const DEFAULT_PERSON_HOME_DE = 'starchefs';
export const DEFAULT_PERSON_HOME_FR = 'fr/chefs';
export const URL_DE_SEARCH = '/suche';
export const URL_FR_SEARCH = '/fr/search';
export const URL_DE_KEYWORD = 'stichworte';
export const URL_FR_KEYWORD = 'fr/mots-cles';
export const URL_DE_VIDEOS = 'videos';
export const URL_FR_VIDEOS = 'fr/videos';
export const URL_DE_RECIPES = 'rezepte';
export const URL_FR_RECIPES = 'fr/recettes';
export const ROUTE_451 = '451';
export const ROUTE_HOT_TEN = 'hot-ten';
export const ROUTE_INSTA_FEED_DE = 'starchefs-auf-instagram';
export const ROUTE_INSTA_FEED_FR = 'fr/chefs-instagram';
export const ROUTE_PARTNERS_DE = 'partner';
export const ROUTE_PARTNERS_FR = 'fr/partenaires';
export const ROUTES_WITH_CUSTOM_PIANO_TRACKING = [ROUTE_451];
export const ROUTE_STYLEGUIDE = 'styleguide';
export const ROUTE_AUTHORS = 'unsere-redaktion';
export const ROUTE_AUTHORS_FR = 'fr/nos-redacteurs';

export const GOOGLE_RECAPTCHA_KEY = '6LcqXPYZAAAAAPnSq8ZCY-Tk2Ub-0wr14JRCs4Fp';
export const MODAL_ROOT_ID = 'modal-root';
