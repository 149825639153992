/* istanbul ignore file */

import React, { Fragment, ReactElement } from 'react';

const StrictMode = ({ children }): ReactElement => {
  if (__USE_STRICT_MODE__) {
    return <React.StrictMode>{children}</React.StrictMode>;
  }

  return children;
};

export default (__USE_STRICT_MODE__ && StrictMode) || Fragment;
