import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { truncateByWord } from '../../../../../shared/helpers/utils';
import {
  UTILITY_BAR_ORIGIN_HEADER,
  UTILITY_BAR_OVERLAY_ORIGIN_HEADER,
} from '../UtilityOverlay/constants';
import {
  UtilityHeaderBarFactoryOptions,
  UtilityHeaderBarProps,
} from './typings';

const UtilityHeaderBar = ({
  UtilityBar,
  UtilityOverlay,
  styles,
  truncateTitleLength,
  appOverlayTitle = 'Artikel teilen',
  appCurrentlyReadingMessage = 'Sie lesen:',
}: UtilityHeaderBarFactoryOptions) => {
  const UtilityHeaderBar = ({
    articleData,
    isScrolledToCollapse,
    isSocialBarVisible,
    enabledUtilities,
    enabledOverlayUtilities,
    showTitle = true,
    overlayTitle,
    isTopBar = false,
    hideIconLabel = false,
  }: UtilityHeaderBarProps): ReactElement<any> => {
    if (!isSocialBarVisible) {
      return null;
    }

    return (
      <div
        data-testid="utility-header-bar-wrapper"
        className={classNames(styles.Wrapper, {
          [styles.HiddenForDesktop]: isTopBar,
          [styles.Move]: isScrolledToCollapse,
        })}
      >
        <div className={styles.ContentWrapper}>
          {showTitle && (
            <div className={styles.TitleWrapper}>
              {appCurrentlyReadingMessage}
              <div className={styles.Title}>{`${truncateByWord(
                articleData?.title || '',
                truncateTitleLength,
              )}`}</div>
            </div>
          )}

          {enabledUtilities &&
            Array.isArray(enabledUtilities) &&
            enabledUtilities.length > 0 && (
              <div className={styles.UtilityBarWrapper}>
                <UtilityBar
                  enabledUtilities={enabledUtilities}
                  origin={UTILITY_BAR_ORIGIN_HEADER}
                  hideIconLabel={hideIconLabel}
                >
                  {({ isOverlayVisible, toggleOverlayVisible, visibleId }) =>
                    (enabledOverlayUtilities &&
                      Array.isArray(enabledOverlayUtilities) &&
                      enabledOverlayUtilities.length > 0 && (
                        <div className={styles.UtilityOverlayWrapper}>
                          <UtilityOverlay
                            visibleId={visibleId}
                            isScrolledToCollapse={isScrolledToCollapse}
                            overlayTitle={overlayTitle || appOverlayTitle}
                            isOverlayVisible={isOverlayVisible}
                            toggleOverlayVisible={toggleOverlayVisible}
                            enabledUtilities={enabledOverlayUtilities}
                            isUsingPortal={true}
                            origin={UTILITY_BAR_OVERLAY_ORIGIN_HEADER}
                          />
                        </div>
                      )) ||
                    null
                  }
                </UtilityBar>
              </div>
            )}
        </div>
      </div>
    );
  };

  return UtilityHeaderBar;
};

export default UtilityHeaderBar;
