import {
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_LINKEDIN,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_PINTEREST,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_WHATSAPP,
} from '../../../../../../../shared/constants/utilitybar';

export const MAP_URL_FR = '/fr/map';
export const MAP_URL_DE = '/map';
export const NEWSLETTER_URL_FR = '/fr/newsletter-inscription';
export const NEWSLETTER_URL_DE = '/newsletter-abonnieren';
export const SHOP_URL_DE = 'https://www.online-kiosk.ch/gault-millau';
export const SHOP_URL_FR = 'https://boutique.illustre.ch/gault-millau-2023';

export const UTILITYHEADERBAR_OVERLAY_CONFIG = [
  UTILITY_TYPE_WHATSAPP,
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_PINTEREST,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_LINKEDIN,
];
