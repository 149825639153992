import React from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import classNames from 'classnames';
import {
  TIME_ELAPSED_FORMAT_MONTHNAME_FULL,
  getFormattedElapsedDate,
} from '../../../../../../../../../shared/helpers/dateTimeElapsed';
import { getArticleDate } from '../../../../../../../../../shared/helpers/utils';
import {
  ANCHOR_TITLE,
  ANCHOR_SHORT_TITLE,
} from '../../../../../../../../../shared/constants/content';
import {
  getAllAuthors,
  renderSponsoredOrShortTitle,
} from '../../../../shared/helpers';
import styles from './styles.legacy.css';
import { ArticleLeadProps } from '../../typings';

type ArticleLeadInterviewPropsInner = ArticleLeadProps & {
  windowState: WindowState;
  intl: IntlShape;
};

const ArticleLeadInterview = ({
  article,
  articleColStyle,
  intl,
  language,
}: ArticleLeadInterviewPropsInner) => {
  const renderedAuthors =
    (article.authors &&
      article.authors.edges.length > 0 &&
      getAllAuthors(article, article.authors.edges, intl)) ||
    null;

  return (
    <div
      className={classNames('article-lead', articleColStyle, styles.Wrapper)}
    >
      <div id={ANCHOR_SHORT_TITLE}>
        {renderSponsoredOrShortTitle(article, styles.ShortTitle)}
      </div>
      <h1 id={ANCHOR_TITLE} className={styles.Title}>
        {article.title || ''}
      </h1>

      <p className={styles.Lead}>{article.lead || ''}</p>
      <div className={styles.AuthorWrapper}>
        {renderedAuthors}

        <span className={styles.DateWrapper}>
          {getFormattedElapsedDate({
            createDate: getArticleDate(article),
            format: TIME_ELAPSED_FORMAT_MONTHNAME_FULL,
            language: language.toUpperCase(),
          })}
        </span>
      </div>
    </div>
  );
};

export default compose(injectIntl)(ArticleLeadInterview);
