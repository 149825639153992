import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import settingsStateSelector from '../../../../../../shared/selectors/settingsStateSelector';
import Link from '../../../../../../../common/components/LinkLegacy';
import { DEFAULT_LANGUAGE } from '../../../Navigation/components/LanguageSwitch';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { FooterInnerProps } from './typings';

const FooterInner: FC<FooterInnerProps> = ({
  footerPrimaryMenu,
  footerPrimaryMenuFr,
}) => {
  const language = useSelector(
    (state) => settingsStateSelector(state).language,
  );
  const primaryMenu =
    language === DEFAULT_LANGUAGE ? footerPrimaryMenu : footerPrimaryMenuFr;

  if (
    !Array.isArray(primaryMenu?.links?.edges) ||
    primaryMenu?.links?.edges.length < 1
  ) {
    return null;
  }

  return (
    <>
      {primaryMenu.links.edges.map(
        (menuGraphListItem: MenuTreeItemEdge): ReactElement => (
          <section
            key={`footer-menu-${menuGraphListItem?.node?.id}`}
            className={classNames(grid.ColSm12, styles.FooterNavigation)}
          >
            {Array.isArray(menuGraphListItem?.node?.subtree?.edges) &&
              menuGraphListItem?.node?.subtree?.edges.length > 0 && (
                <ul className={styles.FooterNavigationList}>
                  {menuGraphListItem.node.subtree.edges.map(
                    (menuGraphListItem: MenuTreeItemEdge): ReactElement => {
                      if (!menuGraphListItem?.node?.link?.label) {
                        return null;
                      }

                      return (
                        <li
                          className={styles.ListItem}
                          key={`footer-menu-item${menuGraphListItem?.node?.id}`}
                        >
                          <Link
                            link={menuGraphListItem?.node?.link}
                            className={styles.Link}
                            nofollow
                          >
                            {menuGraphListItem?.node?.link?.label}
                          </Link>
                        </li>
                      );
                    },
                  )}
                </ul>
              )}
          </section>
        ),
      )}
    </>
  );
};

export default FooterInner;
