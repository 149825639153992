export const imageStylesMapping = {
  '16x9_1130': {
    width: 1695,
    height: 953,
  },
  '16x9_1180': {
    width: 1770,
    height: 996,
  },
  '16x9_170_blue': {
    width: 254,
    height: 143,
  },
  '16x9_170': {
    width: 255,
    height: 186,
  },
  '16x9_220_blue': {
    width: 330,
    height: 186,
  },
  '16x9_220': {
    width: 330,
    height: 186,
  },
  '16x9_280': {
    width: 420,
    height: 236,
  },
  '16x9_340': {
    width: 510,
    height: 287,
  },
  '16x9_360': {
    width: 540,
    height: 304,
  },
  '16x9_440': {
    width: 660,
    height: 371,
  },
  '16x9_560': {
    width: 840,
    height: 473,
  },
  '16x9_700': {
    width: 1050,
    height: 591,
  },
  '16x9_800': {
    width: 1200,
    height: 675,
  },
  '16x9_890': {
    width: 1335,
    height: 751,
  },
  '1x1_140': {
    width: 210,
    height: 210,
  },
  '1x1_160_person': {
    width: 240,
    height: 240,
  },
  '1x1_210': {
    width: 315,
    height: 315,
  },
  '1x1_250': {
    width: 375,
    height: 375,
  },
  '1x1_280': {
    width: 420,
    height: 420,
  },
  '1x1_290_person': {
    width: 439,
    height: 439,
  },
  '1x1_410': {
    width: 615,
    height: 615,
  },
  '1x1_495': {
    width: 743,
    height: 743,
  },
  '1x1_640': {
    width: 960,
    height: 960,
  },
  '1x1_660': {
    width: 990,
    height: 990,
  },
  '1x1_70': {
    width: 105,
    height: 105,
  },
  '1x2_218': {
    width: 327,
    height: 654,
  },
  '2x1_1280': {
    width: 1920,
    height: 960,
  },
  '2x1_800': {
    width: 1200,
    height: 600,
  },
  '2x3_210': {
    width: 315,
    height: 473,
  },
  '2x3_280': {
    width: 420,
    height: 630,
  },
  '2x3_305': {
    width: 458,
    height: 686,
  },
  '2x3_360': {
    width: 540,
    height: 810,
  },
  '2x3_960': {
    width: 1140,
    height: 1710,
  },
  '3x2_210': {
    width: 315,
    height: 210,
  },
  '3x2_280': {
    width: 420,
    height: 280,
  },
  '3x2_440': {
    width: 660,
    height: 440,
  },
  '3x2_770': {
    width: 1155,
    height: 770,
  },
  '3x2_1000': {
    width: 1500,
    height: 1000,
  },
  '3x4_360': {
    width: 270,
    height: 360,
  },
  '3x4_960': {
    width: 720,
    height: 960,
  },
  '4x5_480': {
    width: 480,
    height: 600,
  },
  '8x3_1130': {
    width: 1695,
    height: 636,
  },
  '8x3_890': {
    width: 1335,
    height: 501,
  },
  banner_large: {
    width: 0,
    height: 190,
  },
  banner_medium: {
    width: 0,
    height: 130,
  },
  banner_small: {
    width: 0,
    height: 90,
  },
  beo_menu_background: {
    width: 0,
    height: 700,
  },
  book_teaser: {
    width: 0,
    height: 350,
  },
  brightcove_videos_list_thumbnail: {
    width: 68,
    height: 45,
  },
  focusbox_red_320: {
    width: 320,
    height: null,
  },
  focusbox_red_480: {
    width: 438,
    height: null,
  },
  header_12_5: {
    width: 2800,
    height: 1167,
  },
  header_16_9_large: {
    width: 2048,
    height: 1152,
  },
  header_16_9_small: {
    width: 640,
    height: 360,
  },
  header_16_9: {
    width: 960,
    height: 540,
  },
  header_blur_1200: {
    width: 2400,
    height: 452,
  },
  header_blur_320: {
    width: 479,
    height: 165,
  },
  header_blur_480: {
    width: 759,
    height: 260,
  },
  header_blur_760: {
    width: 959,
    height: 388,
  },
  header_blur_960: {
    width: 1199,
    height: 388,
  },
  header_teaser_1200: {
    width: 1177,
    height: 475,
  },
  header_teaser_320: {
    width: 277,
    height: 195,
  },
  header_teaser_480: {
    width: 438,
    height: 300,
  },
  header_teaser_760: {
    width: 740,
    height: 384,
  },
  header_teaser_960: {
    width: 937,
    height: 384,
  },
  header_teaser_red_1200: {
    width: 1177,
    height: 475,
  },
  header_teaser_red_320: {
    width: 277,
    height: 195,
  },
  header_teaser_red_480: {
    width: 438,
    height: 300,
  },
  header_teaser_red_760: {
    width: 740,
    height: 384,
  },
  header_teaser_red_960: {
    width: 937,
    height: 384,
  },
  inline_image_1200: {
    width: 900,
    height: 0,
  },
  inline_teaser_480: {
    width: 219,
    height: 146,
  },
  inline_teaser_760: {
    width: 255,
    height: 170,
  },
  inline_teaser_960: {
    width: 250,
    height: 300,
  },
  inline_teaser_red_960: {
    width: 250,
    height: 300,
  },
  inline_teaser_title_320: {
    width: 277,
    height: 79,
  },
  inline_teaser_title_480: {
    width: 438,
    height: 146,
  },
  inline_teaser_title_760: {
    width: 453,
    height: 170,
  },
  inline_teaser_title_red_480: {
    width: 438,
    height: 146,
  },
  inline_teaser_title_red_760: {
    width: 453,
    height: 170,
  },
  large: {
    width: 480,
    height: 480,
  },
  media_entity_browser_thumbnail: {
    width: 350,
    height: 200,
  },
  medium: {
    width: 220,
    height: 220,
  },
  ministage_l: {
    width: 360,
    height: 0,
  },
  ministage_m: {
    width: 160,
    height: 0,
  },
  scaleh_120: {
    width: 0,
    height: 180,
  },
  scalew_140: {
    width: 210,
    height: 0,
  },
  scalew_280: {
    width: 420,
    height: 0,
  },
  scalew_440: {
    width: 660,
    height: 0,
  },
  scalew_700: {
    width: 1050,
    height: 0,
  },
  scalew_800: {
    width: 1200,
    height: 0,
  },
  teaser_1_1_small: {
    width: 200,
    height: 200,
  },
  teaser_1_1: {
    width: 400,
    height: 400,
  },
  teaser_3_2_large: {
    width: 800,
    height: 533,
  },
  teaser_3_2_small: {
    width: 610,
    height: 406,
  },
  teaser_3_2: {
    width: 443,
    height: 295,
  },
  teaser_l_1200: {
    width: 551,
    height: 384,
  },
  teaser_l_320: {
    width: 277,
    height: 195,
  },
  teaser_l_480: {
    width: 438,
    height: 300,
  },
  teaser_l_760: {
    width: 340,
    height: 300,
  },
  teaser_l_960: {
    width: 431,
    height: 300,
  },
  teaser_m_1200: {
    width: 263,
    height: 384,
  },
  teaser_m_320: {
    width: 277,
    height: 140,
  },
  teaser_m_480: {
    width: 210,
    height: 300,
  },
  teaser_m_760: {
    width: 160,
    height: 300,
  },
  teaser_m_960: {
    width: 203,
    height: 300,
  },
  teaser_red_l_1200: {
    width: 551,
    height: 384,
  },
  teaser_red_l_320: {
    width: 277,
    height: 195,
  },
  teaser_red_l_480: {
    width: 438,
    height: 300,
  },
  teaser_red_l_760: {
    width: 340,
    height: 300,
  },
  teaser_red_l_960: {
    width: 431,
    height: 300,
  },
  teaser_red_m_1200: {
    width: 263,
    height: 384,
  },
  teaser_red_m_320: {
    width: 277,
    height: 140,
  },
  teaser_red_m_480: {
    width: 210,
    height: 300,
  },
  teaser_red_m_760: {
    width: 160,
    height: 300,
  },
  teaser_red_m_960: {
    width: 203,
    height: 300,
  },
  teaser_red_s_1200: {
    width: 263,
    height: 180,
  },
  teaser_red_s_480: {
    width: 210,
    height: 140,
  },
  teaser_red_s_760: {
    width: 340,
    height: 300,
  },
  teaser_red_s_960: {
    width: 431,
    height: 300,
  },
  teaser_s_1200: {
    width: 263,
    height: 180,
  },
  teaser_s_320: {
    width: 277,
    height: 79,
  },
  teaser_s_480: {
    width: 210,
    height: 140,
  },
  teaser_s_760: {
    width: 160,
    height: 139,
  },
  teaser_s_960: {
    width: 203,
    height: 138,
  },
  thumbnail: {
    width: 100,
    height: 100,
  },
  '16x9_placeholder': {
    width: 48,
    height: 27,
  },
  '8x3_placeholder': {
    width: 48,
    height: 18,
  },
  '1x1_placeholder': {
    width: 40,
    height: 40,
  },
  '2x1_placeholder': {
    width: 40,
    height: 20,
  },
  '1x2_placeholder': {
    width: 20,
    height: 40,
  },
  '2x3_placeholder': {
    width: 40,
    height: 60,
  },
  '3x2_placeholder': {
    width: 36,
    height: 24,
  },
};
